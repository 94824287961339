@import "../../variables";

/* Customize the label (the new_checkbox) */
.new_checkbox {
    font-size: $comment;
    display: inline-flex;
    position: relative;
    margin: auto 0;
    width: 4.8rem;
    height: 4.8rem;
    user-select: none;
    cursor: pointer;
  }
  
  /* Hide the browser's default checkbox */
  .new_checkbox input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    cursor: pointer;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    height: 2rem;
    width: 2rem;
    border: 0.1rem solid $primary-color;
    margin: auto auto auto 0;
  }
  
  /* When the checkbox is checked, add a blue background */
  .new_checkbox input:checked ~ .checkmark {
    background: $ok;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    top: 2rem;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .new_checkbox input:checked ~ .checkmark:after {
    display: inline-flex;
  }
  
  /* Style the checkmark/indicator */
  .new_checkbox .checkmark:after {
    left: 0.7rem;
    top: 1.6rem;
    width: 0.5rem;
    height: 1rem;
    border: solid white;
    border-width: 0 0.3rem 0.3rem 0;
    transform: rotate(45deg);
  }
  