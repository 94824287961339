@import "../../variables";

.container {
    width: 80%;
    margin: 5.8rem auto auto auto;
}

.banner_color {
    background-color: $resources-color;
}

.btn_active {
    background-color: $resources-color;
    box-shadow: $card-shadow;
}

.btn_text {
    color: white;
    font-size: $p;
}

.btn_active:hover {
    background-color: $resources-color;
}

.tabViewsEval {
    align-items: center;
}

.noReport {
    color: black;
    background-color: white;
    margin: 0 auto;
    border-radius: 3px;
    padding: 1.5rem;
}

.pError {
    text-align: center;
}