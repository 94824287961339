@import "../../variables";

.cardSize {
  padding: 2.8rem 2.8rem 0 2.8rem;
  margin: auto;
  width: 28rem;

  @media (min-width: $tablet) {
    padding: 4.8rem 4.8rem 1rem 4.8rem;
    margin: auto;
    min-width: 30rem;
  }
}

.wrapper {
    display: flex;
    height: 100%;
    padding: 3.2rem;
    margin: 3.2rem;
    border: 1px solid $secondary-color;

  @media (min-width: $tablet) {
    width: 50%;
    height: 50%;
    margin: auto;
  }

  @media (min-width: $desktop) {
    width: 29rem;
    height: 45rem;
  }
}

.container {
    display: flex;
    flex-direction: column;
    margin: auto;

  @media (min-width: $tablet) {
    max-width: 29rem;
  }
}

.header {
  display: flex;
  flex-direction: column;
  margin: 2.6rem auto 4.8rem;
}

.logo {
  width: 90%;
  margin: auto;

//   @media (min-width: $tablet) {
//       width: 70%;
//   }
}

.logo_img {
  display: block;
  width: 100%;
  height: auto;
}

.title {
    font-size: 2.1rem;
    font-weight: bolder;
    display: flex;
    margin: 6.4rem auto 0 auto;
    color: $secondary-color;
}

.form {
  display: flex;
  flex-direction: column;
}

.btn_margins {
  margin: 2.4rem 0;
}
