@import "../../../../../variables";

.input {
    width: 80%;
    margin: 0 auto;
    padding-top: 2rem;
    text-align: left;
}

.submitBtn {
    border-radius: 3px;
    width: auto;
    padding: 0.5rem 2rem 0 2rem;
    font-size: 1.3rem;
    color: white;
    background-color: $realized;
    margin: 0 auto;
    margin-top: 1.5rem;
    margin-bottom: 2rem;

    @media (min-width: $desktop) {
        padding-top: 0.7rem;
    }
}

.coordContainer {
    width: auto;
    @media (min-width: $desktop) {
        display: flex;
        justify-content: space-evenly;
    }
}

.subtitle {
    width: 80%;
    margin: 1.6rem auto;
    color: $accent-color;
    font-size: $h6;
    font-weight: 300;
    text-align: left;

    @media (min-width: $desktop) {
        margin: 1.6rem 0 1.6rem 3.2rem;
    }
}

.coordInput {
    @media (min-width: $desktop) {
        width: 80%;
    }
}