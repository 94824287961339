@import "../../variables";

.container {
    max-width: 100%;
    min-width:100%;
    margin: auto;
    background-color: #dddddd59;
    border-radius: 0.8rem;
    cursor: pointer;

    @media (min-width: $tablet) {
        max-width: 30rem;
        min-width: 30rem;
    }
}

.wrapper {
    display: flex;
    padding: 0.8rem;
}

.text_wrapper {
    margin: 0.8rem auto 0.8rem 0.8rem;
}

.icon_wrapper {
    margin: auto 1.6rem;
}

.remove_wrapper {
    display: flex;
    cursor: pointer;
}

.filename {
    font-size: $p;
    font-weight: $bold;
    color: $accent-color;
    margin: 0 auto 0.8rem 0;
}

.disabled_filename {
    font-size: $p;
    font-weight: $bold;
    color: $disabled-color;
    margin: 0 auto 0.8rem 0;
}

.label {
    font-size: $comment;
    color: $accent-color;
    margin: 0 auto 0.8rem 0;
    cursor: pointer;
}

.disabled_label {
    font-size: $comment;
    color: $disabled-color;
    margin: 0 auto 0.8rem 0;
    cursor: default;
}

.remove_btn {
    border: 0;
    background-color: transparent;
    font-size: $comment;
    padding: 0;
    color: red;
    z-index: 1000;
}

.remove_icon {
    height: 1.6rem;
    margin-left: 0.8rem
}

.icon_wrapper {
    width: 3.2rem;
}

.imgIconColor {
    filter: $icon-img;
}

.pdfIconColor {
    filter: $icon-pdf;
}

.wordIconColor {
    filter: $icon-word;
}

.powerPointIconColor {
    filter: $icon-ppt;
}

.excelIconColor {
    filter: $icon-excel;
}

.disabeldIconColor {
    filter: $icon-disabled;
}
