@import "../../../../../variables";

.container {
    display: flex;
    flex-direction: column;
    background-color: $secondary-color;
    padding: 1.6rem;
    z-index: -10;
    
    @media (min-width: $tablet) {
        padding: 3.4rem 4.8rem 2.4rem 4.8rem;
        margin-bottom: 3.2rem;
    }
}

.calendar {
    height: 60rem
}

.loading_bg {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-image: radial-gradient($secondary-color, #ffffff6b);
}

.spinner_text {
    text-align: center;
    font-size: 1.2rem;
    color: $accent-color;
}

.partTime_cell {
    background-color: $cre-color;
    color: white;
}

.card_title {
    font-size: 1.6rem;
    margin: 0 0 0.8rem 0;
}

.card_teacher {
    font-size: 1.2rem;
    margin: 0 0 0.4rem 0;
    color: black;
}

.card_location {
    font-size: 1.2rem;
    margin: 0;
    color: black;
}

.strikethrough {
    text-decoration:line-through;
}

.scheduled {
    color: #1a2ca9;
}

.realized {
    color: #009c19;
}

.canceled {
    color: #757575;
    text-decoration:line-through;
}


.canceled_teacher {
    color: $primary-color;
}

.canceled_loc {
    color: $primary-color;
}

.replaced {
    color: #757575;
    text-decoration:line-through;
}