@import "../../../../../variables";

.container {
    display: flex;
    flex-direction: column;
    border-bottom: 3px solid $biblio-color;
}

.header {
    display: flex;
    background-color: $biblio-color;
    border-radius: 0.8rem 0.8rem 0 0;
    color: white;
    height: 4rem;
}

.wrapper_subject {
    margin: auto 0;
    text-align: center;
    width: 40%;

    @media (min-width: $tablet) {
        margin: auto;
        text-align: left;
        width: auto;
    }
}

.wrapper_subject_alt {
    margin: auto;
    text-align: center;
}

.wrapper_item {
    margin: auto 0;
    text-align: center;
    width: 11rem;
}

.wrapper_status {
    margin: auto 0;
    text-align: center;
    width: 25rem;
}

.header_item {
    text-align: center;
    margin: auto;
}

.wrapper {
    display: flex;
    margin: auto 0;
    width: 30%;
}

.bottom {
    background-color: $docs-color;
    height: 0.4rem;
}
