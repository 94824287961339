@import "./variables";

.rbc-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: $event-padding;
    background-color: $event-bg;
    border-radius: $event-border-radius;
    color: $event-color;
    text-align: left;
    cursor: pointer;

    .rbc-slot-selecting & {
        cursor: inherit;
        pointer-events: none;
    }

    &.rbc-selected {
        background-color: darken($event-bg, 10%);
    }

    &:focus {
        outline: 5px auto $event-outline;
    }
}

.rbc-event-label {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 0 0.8rem 0;
    font-size: 80%;
    color: black;
}

.rbc-event-overlaps {
    box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.rbc-event-continues-later {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
