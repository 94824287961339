@import "../../../variables";

.link {
    text-decoration: none;
    margin: 1rem auto 0 auto;
    color: $primary-color;
    width: fit-content;
    width: -moz-fit-content;
    padding: 1.6rem 0;

    @media (min-width: $tablet) {
        margin: auto 0;
        padding: unset;
    }
}

.link:hover {
    color: lighten($primary-color, 15%);
}