@import "../../variables";

.mainContainer {
    background-color: #ffffff;
    color: black;
    width: 90%;
    margin: 0 auto;
    margin-top: 10rem;
    border-radius: 4px;
    padding: 0.5rem 1rem 3rem 1rem;

    @media (min-width: $tablet) {
        width: 55%;
    }
}

.titleP {
    font-size: 2.5rem;
    text-align: center;
    font-weight: 500;
}

.linkP {
    text-align: center;
}

.checboxContainer {
    display: flex;
    width: 90%;
    margin: 0 auto;
    @media (min-width: $tablet) {
        width: 75%;
    }
}

.checkboxP {
    margin-left: 5%;
    @media (min-width: $tablet) {
        margin-left: 2%;;
    }
}

.btnStyles {
    background-color: $faq-color;
    color: white;
    margin: 0 auto;
    width: 45%;

    @media (min-width: $tablet) {
        width: 35%;
    }
}

.btnStyles:hover{
    background-color: darken($faq-color, 7%);
}

.btn_text {
    font-size: 1.3rem;
    font-weight: 500;
    text-align: center;
    vertical-align: super;
}

.btnStylesDisabled {
    background-color: rgb(161, 161, 161);
    color: white;
    margin: 0 auto;
    width: 30%;
}