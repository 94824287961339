@import "../../variables";

.banner {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 4.8rem;
    border-radius: 0.4rem 0.4rem 0 0;

    @media (min-width: $tablet) {
        height: 6rem;
    }
}

.furtherInfoHeight {
    height: 11rem;
    font-size: 1.3rem;
    @media (min-width: $tablet) {
        height: 12rem;
        font-size: 1.5rem;
    }
}

.banner_text {
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: 700;
    color: $secondary-color;
    text-align: center;

    @media (min-width: $tablet) {
        font-size: 2.1rem;
    }
}

.btn_style {
    position: absolute;
    height: 100%;
    right: 0;

    @media (min-width: $tablet) {
        right: 1rem;
    }
}

.btn_icon {
    filter: $icon-white;

    @media (min-width: $tablet) {
        height: unset;
        margin: auto;
    }
}

.furtherInfo {
    color: $secondary-color;
    margin: 0 2rem 1rem 2rem;
    text-align: center;
}