@import "./variables";

.rbc-row {
    display: flex;
    flex-direction: row;
}

.rbc-row-segment {
    padding: 0 1px 1px 1px;

    .rbc-event-content {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.rbc-selected-cell {
    background-color: $date-selection-bg-color;
}

.rbc-show-more {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: rgba(255, 255, 255, 0.3);
    z-index: $event-zindex;
    font-weight: bold;
    font-size: 85%;
    height: auto;
    line-height: normal;
}

.rbc-month-view {
    position: relative;
    border: 1px solid $calendar-border;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;

    height: 100%; // ie-fix
}

.rbc-month-header {
    display: flex;
    flex-direction: row;
}

.rbc-month-row {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 0 0; // postcss will remove the 0px here hence the duplication below
    flex-basis: 0px;
    overflow: hidden;

    height: 100%; // ie-fix

    & + & {
        border-top: 1px solid $cell-border;
    }
}

.rbc-date-cell {
    flex: 1 1 0;
    min-width: 0;
    padding-right: 5px;
    text-align: right;

    &.rbc-now {
        font-weight: bold;
    }

    > a {
        &,
        &:active,
        &:visited {
            color: inherit;
            text-decoration: none;
        }
    }
}

.rbc-row-bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    overflow: hidden;
}

.rbc-day-bg {
    flex: 1 0 0%;

    & + & {
        border-left: 1px solid $cell-border;
    }

    .rbc-rtl & + & {
        border-left-width: 0;
        border-right: 1px solid $cell-border;
    }
}

.rbc-overlay {
    position: absolute;
    z-index: $event-zindex + 1;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    padding: 10px;

    > * + * {
        margin-top: 1px;
    }
}

.rbc-overlay-header {
    border-bottom: 1px solid #e5e5e5;
    margin: -10px -10px 5px -10px;
    padding: 2px 10px;
}
