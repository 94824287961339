@import "../../../../../variables";


.modal_container {
    height: 95vh;
    
    @media (min-width: $tablet) {
        height: 90vh;
        min-width: 70vw;
    }
}

.banner_color {
    background-color: $biblio-color;
}

.wrapper {
    width: 90%;
    height: 100%;
    margin: 0 auto;
}

.total_wrapper {
    display: flex;
    font-size: 1.8rem;
    font-weight: $regular;
    color: $accent-color;
    margin: 3.2rem auto;

    @media (min-width: 450px) {
        margin: 1.6rem 0 auto auto;
    }
}

.total_label {
    margin: auto 0 auto auto;
}

.total_amount {
    margin: auto 1.6rem auto 0.8rem;
    font-size: 1.8rem;
    font-weight: $bold;
    // width: 9rem;
    text-align: end;
}

.btn_active {
    background-color: $biblio-color;
    box-shadow: $card-shadow;
    margin: 3.2rem auto;
}

.btn_active:hover {
    background-color: darken($biblio-color, 15%);
}

.btn_text {
    // text-transform: uppercase;
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}

.pdfIcon {
    width: 3.2rem;
}

.upload_btn {
    cursor: pointer;
}

.overflow {
    display: flex;
    flex-direction: column;
    height: calc(100% - 10.1em);
    overflow: auto;

    @media (min-width: $landscape) {
        height: calc(100% - 9.1em);
    }

    @media (min-width: $tablet) {
        height: calc(100% - 13.2em);
    }

    @media (min-width: $desktop) {
        height: calc(100% - 12em);
    }
}

.spinner_text_color {
    color: $accent-color;
    font-size: 1.2rem;
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 3.2rem auto 0 auto;

    @media (min-width: 450px) {
        flex-direction: row;
    }
}

.logo {
    width: 40%;
    margin: 2rem 0 2rem 0;
    cursor: pointer;

    @media (min-width: 450px) {
        margin: 0 0 0 6.8rem;
    }
}

.form_wrapper {
    display: inherit;
    flex-direction: column;
    width: 100%;
    align-items:initial;

    @media (min-width: 450px) {
        width: 70%;
        flex-direction: row;
    }
}

.form_number {
    width: 100%;

    @media (min-width: 450px) {
        width: 40%;
    }
}

.form_date {
    width: 100%;
    margin: 0;

    @media (min-width: 450px) {
        width: 40%;
        margin: 0 0 0 6.8rem;
    }
}

.note {
    font-size: $comment;
    color: $calendar-red;
    margin-top: 2.4rem;
}

.disclaimer {
    font-size: $comment;
    font-weight: $semi-bold;
    color: $accent-color;
    text-align: center;
    margin: 2.4rem auto 0 auto;
}

.isSubmitable {
    color: red;
    font-size: 1.4rem;
    display: flex;
    padding-left: 12rem;
    // width: 80%;
}

.btnStylesDisabled {
    background-color: rgba(182, 182, 182, 0.805);
    box-shadow: $card-shadow;
    margin: 3.2rem auto;
}

.campusSelection {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: $card-shadow;
    width: 70%;
    margin: 0 auto;
    margin-top: 10%;
    border-radius: 5px;
    padding: 3rem;
}

.selectCampus {
    width: 40%;
    margin-top: 1.5rem;
    padding: 2rem;
    border: 1px solid rgb(160, 160, 160);
}