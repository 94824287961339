@import "../../../../../variables";
@import "../../../../../placeholders";


.LinkbtnStyles {
  padding: 0;
  width: 10%;
  background-color: unset;

  @media (min-width: $desktop) {
    padding: 0;
    background-color: unset;
  }
}

.loupe {
  filter: invert(48%) sepia(72%) saturate(324%) hue-rotate(115deg)
    brightness(104%) contrast(91%);
  @media (min-width: $desktop) {
    filter: invert(48%) sepia(72%) saturate(324%) hue-rotate(115deg)
      brightness(104%) contrast(91%);
    margin: 0 0.5rem 0 0.5rem;
  }
}

.spanUndefined {
  color: $error;
  width: 30%;

  @media (min-width: $desktop) {
    width: 20%;
  }
}

.spanSubject {
  font-size: 1.5rem;
  font-weight: $bold;
  color: $accent-color;
  position: absolute;
  left: 0;
  text-align: center;
  width: 100%;
  @media (min-width: $desktop) {
    font-size: $h6;

  }
}

.noNotes {
  font-size: 1.8rem;
  font-weight: $bold;
  // color: $accent-color;
  margin: 0 auto;
}

.caption {
  color: $accent-color;
  // font-style: italic;
  font-size: $comment;
  margin-top: 0.5rem;
}

.groupCaption {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn_active {
  @extend %button-active;
  background-color: $resources-color;
  z-index: 10;
}

.btn_active:hover {
  background-color: darken($resources-color, 5%);
}

.btn_text {
  @extend %button-text;
}