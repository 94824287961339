@import "../../variables";

.container {
    margin: 2.4rem;
    
    @media (min-width: $tablet) {
        width: 80%;
        margin: 5.8rem auto auto auto;
    }
}

.banner_color {
    background-color: $schedule-color;
}

.btn_active {
    background-color: $schedule-color;
    box-shadow: $card-shadow;
}

.btn_active:hover {
    background-color: darken($schedule-color, 5%);
}

.btn_text {
    // text-transform: uppercase;
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}