@import "../../variables";

.wrapper {
    display: inherit;
    flex-direction: column;
    text-decoration: none;

    @media (min-width: $tablet) {
        flex-direction: unset;
    }
}

.img_wrapper {
    background-color: white;
    width: 12rem;
}

.img {
    display: block;
    width: 100%;
}

.text {
    color: $secondary-color;
    font-size: $comment;
    margin: 1.6rem auto;

    @media (min-width: $tablet) {
        margin: auto auto auto 1.6rem;
    }
}
