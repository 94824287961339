@import "../../../../../variables";

.legend_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 2.4rem;

    @media (min-width: $tablet) {
        flex-wrap: unset;
    }
}

.wrapper {
    display: flex;
    margin-bottom: 1.6rem;

    @media (min-width: $tablet) {
        margin-bottom: unset;
    }
}

.color_box {
    background-color: $cal-bg;
    height: 1.4rem;
    width: 2.4rem;
    margin: auto;
}

.legend_text {
    color: $accent-color;
    margin: 0 0 0 0.8rem;
    font-weight: $m-thin;
    font-size: $comment;
}

.toCallList {
    background-color: $call-list;
}

.scheduled {
    background-color: $scheduled;
}

.realized {
    background-color: $realized;
}

.invoiced {
    background-color: $invoiced;
}

.cancelled {
    background-color: $cancelled;
}

.replaced {
    background-color: $replaced;
}

.student_scheduled {
    border-top: 3px solid $scheduled;
}

.student_realized {
    border-top: 3px solid $invoiced;
}

.student_cancelled {
    border-top: 3px solid $cancelled;
}