@import "../../../../../variables";

.subheader_wrapper {
    display: flex;
    flex-direction: column;
    background-color: $lbg-color;
    border-radius: 0.8rem;
    padding: 1.6rem;
    margin-bottom: 1.6rem;

    @media (min-width: $tablet) {
        padding: 1.6rem 4.8rem 4.8rem 4.8rem;
        margin-bottom: 6.4rem;
    }
}

.description {
    display: block;
    margin: 1.6rem 0 auto 0;
    font-size: $p;
    color: $primary-color;
    line-height: 1.5;
    padding: 0 1.6rem;
}

.comment {
    font-size: $comment;
    margin: 1.6rem 0 2.4rem 0;
}

.inputs_wrapper {
    display: flex;
    margin: 1.6rem 0;
    justify-content: space-between;
}

.upload_wrapper {
    display: flex;
    flex-direction: column;
}

.comment_wrapper {
    width: 69%;
    margin-right: 3.2rem;
}

.btn_active {
    background-color: $ia-color;
    box-shadow: $card-shadow;
    margin: 0 auto 3.2rem auto;
}

.btn_active:hover {
    background-color: darken($ia-color, 5%);
}

.btn_text {
    margin: auto;
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}

.btn_disabled {
    background-color: $btn-disabled-color;
    margin: 0 auto 3.2rem auto;
    cursor: auto;
}
.pargraph_text {
    font-size: 18;
    color:crimson;  
    display: flex;
    text-align: center;

}