@import "../../../../../variables";

.container {
    display: flex;
    flex-direction: column;
    background-color: $lbg-color;
    border-radius: 0.8rem;
    padding: 0 3.2rem;
}

.btn_active {
    background-color: $biblio-color;
    box-shadow: $card-shadow;
    border-radius: 0.2rem;
    margin: 0 auto 3.2rem auto;
}

.btn_active:hover {
    background-color: darken($biblio-color, 5%);
}

.btn_text {
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}