%typography-title {
    font-family: $title;
    font-weight: $bold;
    color: $primary-color;
}

%typography-alt-title {
    font-family: $title;
    font-weight: $bold;
}
  
%typography-text {
    font-family: $text;
    font-weight: $regular;
    color: $primary-color;
    text-align: justify;
    margin: 0;
    padding: 0;
}

%button-active {
    box-shadow: $card-shadow;
    border-radius: 0.2rem;
}

%button-text {
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}