@import "../../../../../variables";
@import "../../../../../placeholders";

.link {
    display: flex;
    justify-content: space-around;
    align-content: center;
    font-size: 1.1rem;
    border-radius: 0.8rem;
    box-shadow: $card-shadow;
    padding: 0.2rem 0 0.2rem 0;
    background-color: $secondary-color;
    margin: 1rem auto;
    width: 90%;
    height: 4.4rem;
  
    @media (min-width: $desktop) {
      display: flex;
      justify-content: space-evenly;
    }
  }

.span {
    color: $accent-color;
    font-size: 1.3rem;
    margin: 0.5rem 0 0 0;
    width: 20%;
    @media (min-width: $desktop) {
        
        margin: auto 0;
        font-size: $p;
        text-align: center;
    }
}
.date {
    width: 9%;
}

.label {
    width: 35%;
}
