@import "../../variables";

.container {
    display: flex;
    width: 90%;
    margin: 0 auto;
    border-radius: 0.8rem;
    background-color: $secondary-color;
    box-shadow: $card-shadow;
    margin-top: 3.2rem;
    padding: 1rem 1rem 1rem 1rem;

    @media (min-width: $desktop) {
        display: flex;
        min-height: 5rem;
        border-radius: 0.8rem;
        background-color: $secondary-color;
        box-shadow: $card-shadow;
        margin-top: 3.2rem;
    }
}

.wrapper {
    display: inherit;
    margin: auto;
}

.icon {
    height: 2.4rem;
    margin: auto 1.6rem auto 0;
}

.text {
    color: $bg-color;
    font-weight: $bold;
    margin: auto 0;
    white-space: pre-line;
}

.btn_active {
    background-color: $fin-color;
    box-shadow: $card-shadow;
    height: 3.2rem;
    margin-left: 1.6rem;
    align-self: center;
}

.btn_active:hover {
    background-color: darken($fin-color, 10%);
}

.btn_text {
    margin: 0 auto;
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}