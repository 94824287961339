@import "../../../variables";

.financing {
    color: $schedule-color;
    width: 80%;
    margin: 0 auto;
    margin-top: 2rem;
    padding-bottom: 2rem;

}

.financingP {
    border-top: 1px solid lightgrey;
    font-style: italic;
    padding-top: 2rem;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 2rem;

    &:hover + .Pmontage {
        display: block;
        margin: 0 auto;
        margin-bottom: 1rem;
        background-color: rgb(207, 206, 206);
        border-radius: 5px;
        color: black;
        width: 80%;
        padding: 1.5rem;
    }
}

.Pmontage {
    display: none;
}

.dossier {
    &:hover + .Pmontage {
        display: block;
        margin: 0 auto;
        margin-bottom: 1rem;
        background-color: rgb(207, 206, 206);
        border-radius: 5px;
        color: black;
        width: 80%;
        padding: 1.5rem;
    }
}