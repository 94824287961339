@import "../../variables";

.container {
    width: 80%;
    margin: 5.8rem auto auto auto;
}

.download_icon_color {
    color: $resources-color;
}

/* Student Colors */
.student_banner_color {
    background-color: $resources-color;
}

.student_btn_active {
    background-color: $resources-color;
    box-shadow: $card-shadow;
}

.student_btn_active:hover {
    background-color: $resources-color;
}

/* Teacehr Colors */
.teacher_banner_color {
    background-color: $docs-color;
}

.teacher_btn_active {
    background-color: $docs-color;
    box-shadow: $card-shadow;
}

.teacher_btn_active:hover {
    background-color: $docs-color;
}