@import "../../variables";

.container {
    display: flex;
    height: 4.8rem;
    padding: 0 2.4rem;
    box-shadow: $card-shadow;
    margin-bottom: 1.6rem;

    @media (min-width: $tablet) {
        height: 5.8rem;
        padding: 0 3.2rem;
        margin-bottom: 3.2rem;
    }
}

.icon {
    height: 3rem;
    margin: auto 2.4rem auto 0;
    filter: $icon-white;

    @media (min-width: $tablet) {
        height: 2.4rem;
    }
}

.title {
    font-size: 1.8rem;
    text-transform: uppercase;
    color: $secondary-color;
    margin: auto 0;
    text-align: left;

    @media (min-width: $tablet) {
        font-size: $h6;
    }
}

.btn {
    display: flex;
    height: 100%;
    margin: auto 0 auto auto;
    text-decoration: none;
    cursor: pointer;
}

.btn_icon {
    display: block;
    filter: $icon-white;
    height: 2rem;
    margin: auto 0 auto 0.8rem;

    @media (min-width: $tablet) {
        height: 2.4rem;
    }
}

.btn_text {
    margin: auto 0 auto 0.8rem;
    color: $secondary-color;
    font-weight: $semi-bold;
}