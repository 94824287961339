@import "../../variables";

.container {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    min-width: 90%;
    max-width: 90%;
    background: white;
    border-radius: 0.4rem;
    transform: translate(-50%, -50%);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

    // @media (min-width: $tablet) {
    //     min-width: 90%;
    //     max-width: 80%;
    // }

    @media (min-width: $desktop) {
        min-width: 60%;
        max-width: 80%;
    }

    //     display: flex;
    //     flex-direction: column;
    //     position: fixed;
    //     background: white;
    //     min-width: 60%;
    //     min-height: 6vh;
    //     top: 12vh;
    //     left: calc(50% - (30% + 2.4rem));
    //     border-radius: 0.4rem;
    //     // padding: 2.4rem;
    //     z-index: 100;
    //     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}
