@import "../../variables";

.nav_btns {
    display: flex;
    margin-bottom: 3.2rem;
    justify-content: flex-start;
    gap: 1.6rem;
    flex-wrap: wrap;
}

.btn {
    display: flex;
    height: 3.8rem;
    width: 100%;
    border: 0;
    border-radius: 0.2rem;
    cursor: pointer;

    @media (min-width: $tablet) {
        width: unset;
        padding: 0 3.2rem;
    }
}

.btn:hover {
    background-color: #ffffff20;
    border-radius: 0.2rem;
}

.btn_text {
    text-transform: uppercase;
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
    margin: auto;
}

.btn_textDisable {
    text-transform: uppercase;
    color: rgb(107, 107, 107);
    font-size: $p;
    font-weight: $semi-bold;
    margin: auto;
}

.btnDisable {
    display: flex;
    height: 3.8rem;
    background-color: rgb(37, 37, 37);
    width: 100%;
    border: 0;
    border-radius: 0.2rem;
    cursor: pointer;

    @media (min-width: $tablet) {
        width: unset;
        padding: 0 3.2rem;
    }
}

.btnDisable:hover {
    background-color: #ffffff20;
    border-radius: 0.2rem;
}