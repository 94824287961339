@import "../../variables";

.mainContainer {
    position: relative;
    width: 100%;
    
    @media (min-width: $desktop) {
        display: flex;
        justify-content: space-between;
        height: auto;
        width: 70%;
        margin: 0 auto;
      }
}

.profilContainer {
    background-color: white;
    margin: 0 auto;
    border-radius: 0.4rem;
    padding-top: 3.5rem;
    padding-bottom: 1rem;
    margin-top: 5rem;
    text-align: center;
}

.navContainer {
    position: absolute;
    left: 10%;
    top: 2%;
    display: flex;
    background-color: $realized;
    color: white;
    width: 80%;
    margin: 0 auto;
    justify-content: space-evenly;
    border-radius: 3px;
    box-shadow: $card-shadow;
    font-weight: 300;
    box-sizing: border-box;

    @media (min-width: $desktop) {
        width: 60%;
        left: 7%;
        top: 4%;
    }
}

.nav {
    width: 20%;
}

.submitBtn {
    border-radius: 3px;
    width: auto;
    padding: 0.5rem 2rem 0 2rem;
    font-size: 1.3rem;
    color: white;
    background-color: $realized;
    margin: 0 auto;
    margin-top: 1.5rem;
    margin-bottom: 2rem;

    @media (min-width: $desktop) {
        padding-top: 0.7rem;
    }
}

.coordContainer {
    width: auto;
    @media (min-width: $desktop) {
        display: flex;
        justify-content: space-evenly;
    }
}

.p {
    color: black;
    font-size: 2rem;
    font-weight: 300;
}

.coordInput {
    @media (min-width: $desktop) {
        width: 80%;
    }
}