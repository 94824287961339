@import "../../variables";

.modal_container {
    position: fixed;
    z-index: 1000;
    height: auto;
    // width: 60%;
    margin-left: 8%;
    right: 10%;
    top: 5%;
    background-color: white;
    border-radius: 5px;
    box-shadow: $card-shadow;

    @media (min-width: $tablet) {
        height: auto;
        width: 70%;
        margin: 0 auto;
        right: 15%;
        top: 30%;
    }
}

.banner_color {
    background-color: $esi-color;
}

.modalCore {
    display: flex;
    flex-direction: column;
}

.footer {
    width: 100%;
}

.btn_active {
    background-color: $esi-color;
    box-shadow: $card-shadow;
    border-radius: 0.2rem;
    width: 100%;
}

.btn_active:hover {
    background-color: darken($schedule-color, 5%);
}

.btn_active_alt {
    background-color: $scheduled;
    box-shadow: $card-shadow;
    border-radius: 0.2rem;
}

.btn_active_alt:hover {
    background-color: darken($scheduled, 5%);
}

.btn_text {
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}

.paragraphsStyles {
    width: 80%;
    margin: 0 auto;
    color: black;
    font-size: 2rem;
    padding: 4rem 0 4rem 0;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.link {
    color: rgb(33, 223, 112);
    margin: 0 auto;
    margin-bottom: 5rem;
}

.link:hover{
    color:darken(rgb(33, 223, 112), 5%);
}

.infoP {
    margin: 0 auto;
}