@import "../../../../variables";

.subheader_wrapper {
    display: flex;
    flex-direction: column;
    background-color: $lbg-color;
    border-radius: 0.8rem;
    padding: 0 3.2rem;
}

.subheader_iban {
    font-size: 2.8rem;
    margin: auto auto 2.4rem auto;
}

.iban_message {
    margin: auto auto 2.4rem auto;
}

.subheader {
    font-size: $h6;
    color: $accent-color;
    margin: auto auto auto 0;
}

.btn_active {
    background-color: $biblio-color;
    box-shadow: $card-shadow;
    border-radius: 0.2rem;
    margin: 0 auto 3.2rem auto;
}

.btn_active:hover {
    background-color: darken($biblio-color, 5%);
}

.btn_text {
    // text-transform: uppercase;
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}

.campusSelection {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: $card-shadow;
    margin: 0 auto;
    border-radius: 5px;
    padding: 3rem;
    background-color: white;
}

.selectCampus {
    width: 40%;
    margin-top: 1.5rem;
    padding: 2rem;
    border: 1px solid rgb(160, 160, 160);
}