@import "../../variables";

.container {
    display: flex;
    height: 6rem;
    padding-left: 1rem;
    background-color: $secondary-color;
    box-shadow: $card-shadow;
    margin-bottom: 1.6rem;
    cursor: pointer;

    @media (min-width: $desktop) {
        display: flex;
        height: 4.8rem;
        padding: 0 9rem;
        background-color: $secondary-color;
        box-shadow: $card-shadow;
        margin-bottom: 1.6rem;
        cursor: pointer;
      }

      @media (min-width: $tablet) {
        display: flex;
        height: 4.8rem;
        padding: 0 9rem;
        background-color: $secondary-color;
        box-shadow: $card-shadow;
        margin-bottom: 1.6rem;
        cursor: pointer;
      }
}

.link {
    text-decoration: none;
}

.icon {
    height: 2rem;
    margin: auto 2.4rem auto 0;
    filter: invert(53%) sepia(93%) saturate(273%) hue-rotate(115deg) brightness(91%) contrast(85%);
}

.text {
    text-transform: uppercase;
    color: $bg-color;
    margin: auto 0;
    width: 100%;
    font-weight: $bolder;
    @media (min-width: $desktop) {
        text-transform: uppercase;
        color: $bg-color;
        margin: auto 0;
        font-weight: $bolder;
      }
}

.btn_alt {
    width: 100%;
    border: 0;
    font-size: $p;
}

.btn_container {
    display: flex;
    width: 100%;
}

.warning_icon_color {
    filter: $icon-warning;
}

.info_icon_color {
    filter: $icon-info;
}

.disabled_link {
    pointer-events: none;
    opacity: 0.5;
    text-decoration: none;

  }