@import "../../variables";

.container {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.websites_container {
    display: flex;
    margin: 3.2rem 0;
    justify-content: space-evenly;

    @media (min-width: $desktop) {
        width: 75%;
        margin: 3.2rem auto;
        align-content: space-around;
        justify-content: space-around;
    }
}

.warning_icon_color {
    filter: $icon-warning;
}

.rentree {
    background-color: white;
    padding: 1.5rem;
    border-radius: 5px;
    color: black;
    font-weight: bold;
    width: 80%;
    margin: 0 auto;
    margin-top: 2rem;
}


.important {
    color: red;
    text-decoration: underline;
}

.not_available {
    background-color: #b2b2b5;
}