@import "../../variables";


.container {
    display: flex;
    flex-direction: column;
    color: $accent-color;
    margin: 1.6rem auto;

    @media (min-width: $tablet) {
        margin: 4.8rem auto;
        width: 80%;
        height: 50%;
    }

    @media (min-width: $desktop) {
        margin: 4.8rem auto;
        width: 60%;
        height: 50%;
    }
}

.icon_container {
    width: 6rem;
    margin: 2.4rem auto 3.2rem auto;
}

.ok_icon_container {
    width: 6rem;
    margin: 1.4rem auto 2.2rem auto;

    @media (min-width: $tablet) {
        width: 8rem;
        margin: 2.4rem auto 3.2rem auto;
    }
}

.icon_ok {
    filter: $icon-ok;
    display: block;
    width: 100%;
}

.icon_error {
    filter: $icon-error;
    display: block;
    width: 100%;
}

.icon {
    display: block;
    width: 100%;
}

.ok_title {
    font-size: 1.6rem;
    font-weight: 500;
    margin: auto;
    text-align: center;

    @media (min-width: $tablet) {
        font-size: 2.1rem;
    }
}

.error_title {
    font-size: 2.1rem;
    font-weight: 500;
    margin: 0 auto;
    text-align: center;
}

.error_message {
    text-align: center;
    margin: 4rem auto;
    line-height: 1.5;
    font-size: 1.4rem;
}

.alt_message {
    text-align: center;
    margin: 4rem auto;
    line-height: 1.5;
    font-size: 1.4rem;
}

.btn {
    margin: auto auto 2.4rem auto;
}

.margin {
    margin-bottom: 1.6rem;
}

