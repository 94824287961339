@import "../../variables";

.container {
    width: 80%;
    margin: 5.8rem auto auto auto;
}

.banner_color {
    background-color: $faq-color;
}

.btn_active {
    background-color: $faq-color;
    box-shadow: $card-shadow;
}

.btn_active:hover {
    background-color: $faq-color;
}
