@import "../../../../variables";

.banner_color {
    background-color: $biblio-color;
}

.modalContainer {
    border-radius: 0.4rem;
    min-width: 70vw;
    min-height: 94vh;
    @media (min-width: $desktop) {
        width: 100%;
    }
}

.form {
    overflow: auto;
    height: 85vh;
}

.evalSection {
    border: 1px solid lightgray;
    padding: 1rem 1rem 1rem 1rem;
    margin: 1rem 1rem 1rem 1rem;
    @media (min-width: 800px) {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
    }
}

.selectStyles {
    display: flex;
    flex-direction: column;
    @media (min-width: 800px) {
        display: flex;
        width: 25%;
        margin: 2rem 0 0 0;
    }
}

.label {
    margin: 0 auto 1rem 0;
    font-size: 1.4rem;
}

.labelNote {
    margin: 1rem 0 0.5rem 45%;
}

.input {
    width: 15%;
    margin: 0;
}

.studentContainer {
    border: 1px solid lightgray;
    padding: 1rem 1rem 1rem 1rem;
    margin: 1rem 1rem 1rem 1rem;
}

.student {
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0 0 0 1rem;
}

.inputComment {
    height: 15vh;
    margin: 0 0 3rem 0;
}

.studentName {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.btnStyles {
    background-color: $resources-color;
}

.btnStylesOff {
    background-color: rgb(90, 1, 1);
}


// DESKTOP SETUP

.studentDesktop {
    // display: flex;
    flex-wrap: wrap;
    @media (min-width: 800px) {
        display: flex;
        flex-direction: column;
    }
}

.studentInfo {
    align-items: center;
    border-bottom: 1px solid lightgray;
    @media (min-width: 800px) {
        display: flex;
        padding: 1.2rem 4.8rem;
    }
}


.studentPicture {
    margin: 2em 0 2em 0;
    height: 55px;
    width: 55px;
    padding: 0 1rem 0 0;
}

.paramBar {
    display: flex;
    color: $secondary-color;
    font-weight: $semi-bold;
    background-color: $resources-color;
}

.header_student {
    margin-left: 5rem;
}

.header_note {
    margin-left: 35rem;
}

.header_comment {
    margin-left: 15rem;
}

.studentName {
    width: 20%;
    margin: 0 0 0 2em;
    font-size: 1.3rem;
}

.selectNote {
    width: 25%;
    margin: 0 0rem 0 10rem;
    @media (min-width: 800px) {
        
    }
}

.inputCommentDesktop {
    margin: 0 2rem 0 2rem;
}

.btn_active {
    background-color: $resources-color;
    box-shadow: $card-shadow;
    margin: 3.2rem auto;
}

.btn_active:hover {
    background-color: darken($resources-color, 15%);
}

.btn_text {
    // text-transform: uppercase;
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}

.datePicker {
    font-size: 1.6rem;
    font-weight: 400;
    color: #000000;
    height: 3.8rem;
    background-color: #fff;
    -webkit-appearance: none;
    border: 0;
    border-bottom: 1px solid #ced4da;
    width: 100%;
    margin-bottom: 2rem;
}

.banner_color {
    background-color: $resources-color;
}

.spanErr {
    width: 50%;
    display: block;
    color: $error;
    font-size: 1.3rem;
    margin: 3rem auto 0 auto;
}

.btnStylesDelete {
    border: solid 3px $error;
    background-color: transparent;
}

.btnStylesDelete:hover {
    background-color: $error;
    .btn_text_delete { color: $secondary-color; }
}

.btn_active_delete {
    background-color: transparent;
    box-shadow: $card-shadow;
    margin: 3.2rem auto;
}

.btn_text_delete {
    color: $error;
    font-size: $p;
    font-weight: $semi-bold;
}

.modifyBtn {
    display: flex;
    justify-content: space-evenly;
}

.spinner_text_color{
    color: black;
}

.assessment {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
    width: 8%;
}