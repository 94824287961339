@import "../../../../variables";

.container {
    display: flex;
    flex-direction: column;
    background-color: $lbg-color;
    border-radius: 0.8rem;
    padding: 0 3.2rem;
    margin-bottom: 3.2rem;
}

.warning_icon_color {
    filter: $icon-warning;
}

.qrCodeContainer {
    color: black;
    text-align: center;
}

.important {
    color: red;
    font-weight: bold;
}

.qrCode_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.qrCode {
    width: 85px;
    height: 85px;
}