@import "../../variables";

.body {
    position: relative;
}

.mainContainer {
    // position: relative;
    margin-top: 5rem;
    width: 100%;
    height: auto;
    @media (min-width: $desktop) {
        display: flex;
        margin-top: 5rem;
        justify-content: space-between;
        height: auto;
        width: 70%;
        margin: 0 auto;
      }
}


.dfContainer {
    background-color: white;
    width: 90%;
    margin: 0 auto;
    border-radius: 3px;
    padding-top: 3.5rem;
    padding-bottom: 1rem;
    margin-top: 5rem;
    text-align: center;
}

.navContainer {
    position: absolute;
    left: 10%;
    top: 16%;
    display: flex;
    background-color: $realized;
    color: white;
    width: 80%;
    margin: 0 auto;
    justify-content: space-evenly;
    border-radius: 3px;
    box-shadow: $card-shadow;
    font-weight: 300;
    box-sizing: border-box;

    @media (min-width: $desktop) {
        width: 50%;
        left: 25%;
        top: 110px;
    }
}

.nav {
    width: 20%;
    &:hover {
        cursor: pointer;
    }
}

.navActivate {
    width: 20%;
    text-decoration: underline;
    text-underline-offset: 3px;
}
