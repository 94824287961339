@import "../../../../../variables";

.studentContainer {
    display: flex;
    align-items: center;
    margin: 2rem 10rem 0 10rem;

    @media (min-width: 800px) {
        display: flex;
        margin: 0 3.2rem 0 0;
        align-items: center;
        width: 55%;
    }
}

.studentName {
    color: $accent-color;
    max-width: 100%;
}

.noteInput {
    width: 21%;
    margin: 0 3.2rem 0 0;
}

.commentInput {
    width: 45%;
    margin: 0;
    font-family: $text;
}

.isHidden {
    display: none;
}

.checkboxContainer {
    display: flex;
}

.visible {
    display: flex;
}

.avatar {
    border-radius: 50%;
    height: 45px;
    width: 45px;
}