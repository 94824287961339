@import "../../../../variables";

*::-webkit-scrollbar {
    width: 0px;
}


.listContainer {
    font-size: $comment;
 
    @media (min-width: $desktop) {
        font-size: 1.8rem;
        display: flex;
        flex-direction: column;
        overflow: auto;
        margin: 6.4rem auto 3.2rem auto;
    }
}

.groupsAndLinks {
    margin-bottom: 3.2rem;
    background-color: #f9f9f9;
    padding-bottom: 1.6rem;
    border-radius: 0.8rem;
}

.groupContainer {
    padding : 0 0 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $secondary-color;
    
    @media (min-width: $desktop) {
        padding: 0 6rem;
        margin-bottom: 2.6rem;
        height: 6.4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $secondary-color;
        border-radius: 0.8rem 0.8rem 0 0;
        box-shadow: $card-shadow;
    }
}

.link {
    background-color: lightgrey;
    display:flex;
    justify-content: space-evenly;
    padding: 0.2rem 0 0.2rem 0;
    @media (min-width: $desktop) {
        display:flex;
        justify-content: space-evenly;
    }
}

.LinkbtnStyles {
    padding: 0;
    // height: 30px;
    width: 10%;
    background-color: rgb(212, 212, 212);

    @media (min-width: $desktop) {
        padding: 0;
        height: 30px;
        background-color: rgb(212, 212, 212);
    }
}

.loupe {
    filter: $icon-white;
    @media (min-width: $desktop) {
        filter: $icon-white;
        margin: 0 0.5rem 0 0.5rem;
    }
}

.LinkbtnStyles:hover {
    background-color: rgb(190, 190, 190);
}
.span {
    color: black;
    font-size: 1.5rem;
    margin: 0.5rem 0 0 0;
}

.btn_active:hover {
    background-color: rgb(6, 97, 82);
}

.spanNoReport {
    color: white;
    font-size: 2rem;
    margin: 0 0 0 40rem;
}