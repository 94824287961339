@import "../../../../variables";

.container {
    display: flex;
    flex-direction: column;
    background-color: $lbg-color;
    border-radius: 0.8rem;
    padding: 3.2rem 0;

    @media (min-width: $tablet) {
        padding: 3.2rem 0;
    }
}

.title {
    width: 100%;
    font-size: 2.8rem;
    text-align: center;
    color: $accent-color;

    @media (min-width: $tablet) {
        font-size: 3.2rem;
    }
}