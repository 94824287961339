@import "../../../../../variables";

.container {
    padding: 0.8rem 0 0.8rem 0;
    border-bottom: 2px solid #cccccc;;
    
    @media (min-width: $tablet) {
        border-bottom: 1px solid $calendar-lines;
    }
}

.subwrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: $tablet) {
        flex-direction: row;
    }
}

.subwrapper_responsive {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: $tablet) {
        flex-direction: row;
        width: 70rem;
    }
}

.wrapper_subject {
    text-align: left;
    width: 100%;

    @media (min-width: $tablet) {
        margin: auto auto auto 0;
        text-align: left;
        width: auto;
    }
}

.wrapper_item {
    text-align: left;
    width: 100%;

    @media (min-width: $tablet) {
        margin: auto 0;
        text-align: center;
        width: 50%;
    }
}

.wrapper_tag {
    width: 100%;
    text-align: center;
    margin: 0.8rem 0;

    @media (min-width: $tablet) {
        margin: auto 0;
        text-align: center;
        padding-left: 1.6rem;
    }
}

.title {
    text-align: left;
    color: $bg-color;
    margin: 0.8rem 0 0 0;
    margin: 0;

    @media (min-width: $tablet) {
        margin: auto 0;
    }
}

.text {
    font-size: $comment;
    font-weight: $regular;
    color: $accent-color;
    text-align: left;
    margin: 0.8rem 0 0 0;

    @media (min-width: $tablet) {
        text-align: center;
        margin: unset;
    }
}

.gap {
    margin: auto 1.6rem;
}

.tx_expected {
    color: darken($to-justify-color, 2%);
}

.bg_expected {
    background-color: lighten($to-justify-color, 38%);
}

.tx_pending {
    color: $error;
}

.bg_pending {
    background-color: lighten($unjustified-color, 15%);
}

.tx_validate {
    color: $cre-color;
}

.bg_validate {
    background-color: lighten($cre-color, 55%);
}

.tx_collected {
    color: $justified-color;
}

.bg_collected {
    background-color: $justified-bg-color
}
