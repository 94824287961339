@import "../../variables";

.container {
    background-color: $secondary-color;
    margin-bottom: 3.3rem;
    border-radius: 0.4rem;
    padding: 2rem 2rem 2rem 2rem;

    @media (min-width: $desktop) {
        padding: 6.4rem;
    }
}