@import "../../../../../variables";

.container {
    display: flex;
    flex-direction: column;
}

.table_header {
    display: flex;
    flex-direction: row;
    color: $secondary-color;
    font-weight: $semi-bold;
    background-color: #67DDE2;
}

.menu_div {
    display: flex;
    justify-content: space-evenly;
    margin: 0 0 0 auto;
    width: 45%;
}

.menu {
    margin: auto 0.8rem;
    text-align: center;
}

.menu_item {
    width: 10rem;
}