@import "../../../../variables";

.subheader_wrapper {
    display: flex;
    flex-direction: column;
    background-color: $lbg-color;
    border-radius: 0.8rem;
    padding: 1.6rem;
    margin-bottom: 1.6rem;

    @media (min-width: $tablet) {
        padding: 1.6rem 4.8rem 4.8rem 4.8rem;
        margin-bottom: 6.4rem;
    }
}

.description {
    display: block;
    margin: 1.6rem 0 auto 0;
    font-size: $p;
    color: $primary-color;
    line-height: 1.5;
    padding: 0 1.6rem;
}

.comment {
    font-size: $comment;
    margin: 1.6rem 0 2.4rem 0;
}

.inputs_wrapper {
    display: flex;
    margin: 1.6rem 0;
    justify-content: space-between;
}

.upload_wrapper {
    display: flex;
    flex-direction: column;
}

.comment_wrapper {
    width: 100%;
    margin-right: 3.2rem;
    @media (min-width: $desktop) {
        width: 69%;
    }
}

.btn_active {
    background-color: $esi-color;
    box-shadow: $card-shadow;
    margin: 0 auto 3.2rem auto;
}

.btn_activeCancel {
    background-color: $ia-color;
    box-shadow: $card-shadow;
    margin: 0 auto 3.2rem auto;
}

.btn_activeCancel:hover {
    background-color: darken($ia-color, 5%);
}

.btn_active:hover {
    background-color: darken($esi-color, 5%);
}

.btn_text {
    margin: auto;
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}

.btn_disabled {
    background-color: $btn-disabled-color;
    margin: 0 auto 3.2rem auto;
    cursor: auto;
}

.inputSelect {
    margin-bottom: 3rem;
    margin-top: 2rem;
    @media (min-width: $desktop) {
        width: 78%;
        margin: 0 auto;
        margin-bottom: 3rem;

    }
}

.comment_wrapper {
    @media (min-width: $desktop) {
        margin: 0 auto;
    }
}

.btn_wrapper {
    @media (min-width: $desktop) {
        display: flex;
    }
}

.unjustifiedCheck {
    display: flex;
    margin: 0 auto;
    margin-bottom: 3rem;
}

.unjustifiedP {
    font-weight: bold;
}

.spanIdList0 {
    margin: 0 auto;
    color: red;
    font-size: 1.3rem;
    margin-bottom: 2rem;
}

.nbMax {
    font-size: 1.3rem;
}