@import "../../variables";

.label {
    font-size: 1rem;
    display: flex;
    height: 2.4rem;
    margin: auto 0;
    padding: 0 0.8rem;
    font-weight: $semi-bold;
    text-transform: uppercase;

    @media (min-width: $tablet) {
        font-size: 1.2rem;
        height: 2.8rem;
        margin: auto 0;
        padding: 0 1.6rem; 
    }
}

.text {
    margin: auto;
    // width: 6.9rem;

    @media (min-width: $tablet) {
        width: unset;  
    }
}
