@import "../../variables";

.wrapper {
    display: flex;
    box-shadow: $card-shadow;
    padding: 0 2rem;
}

.accounts_wrapper {
    margin: 3.2rem;
    display: grid;
    grid-gap: 1.6rem;
    grid-template-columns: repeat(2, 2fr);

    @media (min-width: $tablet) {
        grid-template-columns: repeat(3, 3fr);
        margin: 1.6rem 3.2rem;
    }
}

.menu_content {
    height: 75%;
}

.closeBtn {
    display: flex;
    margin: 1.6rem 1.6rem 0 auto;
}

.whiteIcon {
    filter: $icon-white;
}

.account_title {
    margin: 0 0 1.6rem 0;
    text-align: center;
    font-size: 2rem;
    color: $secondary-color;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    height: 4.8rem;
}

.logo_container {
    display: flex;
    align-items: center;
    padding: 1.2rem 0;
    margin: auto auto auto 0;
    height: 4rem;
    
    @media (min-width: $tablet) {
        height: 4.5rem;
        padding: 2.2rem 0;
        padding-top: 3.5rem;
    }
}

.img {
    display: flex;
    height: 130px;
    width: 210px;
}

.nav {
    display: flex;
    flex-direction: inherit;
    margin: auto 0 auto auto;
}

.link_text {
    display: flex;
    margin: auto 0;
}

.icon_color {
    filter: $icon-white;
}

.btn_active {
    background-color: transparent;
    border-radius: 0;
    border-bottom: 2px solid $email-color;
    margin: 0.8rem auto;
    padding: 0 0.8rem;
    cursor: auto;
}

.btn_text_active {
    font-size: $p;
    font-weight: $semi-bold;
    color: $email-color;
    margin: auto;
}

.btn {
    border-radius: 0;
    background-color: transparent;
    margin: 0.8rem auto;
    border-bottom: 2px solid $bg-color;
    padding: 0 0.8rem
}

.btn:hover {
    border-bottom: 2px solid $secondary-color;
}

.btn_text {
    font-size: $p;
    font-weight: $semi-bold;
    color: $secondary-color;
    margin: auto;
}

.burgerContainer {
    display: flex;
    align-items: center;
}

.burger {
   color: $secondary-color;
   z-index: 1000;
}

.displayedMenu {
    background-color: $bg-color;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    box-shadow: $card-shadow;
    z-index: 1000;

    @media (min-width: $tablet) {
        width: 35rem;
    }
}

.avatar {
    height: 12rem;
    width: 12rem;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 1rem;
    border-radius: 50%;
}

.styleAvatar {
    height: 12rem;
    width: 12rem;
    border-radius: 50%;
    object-fit: cover;
}

.studentName {
    color: $secondary-color;
    font-weight: $semi-bold;
    font-size: $h5;
    margin-bottom: 4rem;
    text-align: center;
}

.nav_link {
    font-size: $p;
    text-decoration: none;
    color: $secondary-color;
    height: 2.4rem;
    border-bottom: 2px solid $bg-color;
    margin: 1.6rem auto;
}

.nav_link:hover {
    color: $email-color;
    border-bottom: 2px solid $email-color;
}

.linkContainer {
    display: flex;
    flex-direction: column;
}

.logout_btn {
    width: 100%;
    height: 4.8rem;
    border-radius: 0;
    background-color: transparent;
    box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.2);
    margin: 1.6rem auto;
    padding: 0 0.8rem
}

.adminBtn {
    background-color: $faq-color;
    box-shadow: $card-shadow;
    margin: auto auto auto 0;
}

.adminBtn:hover {
    background-color: darken($faq-color, 5%);
}