.offersTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  text-align: left;
  background-color: #f9f9f9;
  border-radius: 3px;

  th {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    background-color: #f9f9f9;
  }

  td {  
    // color: white;
    padding: 12px;
    // border-bottom: 1px solid #ddd;
    //background-color: #f9f9f9;
  }

  th {
    background-color: #0bec9d;
    color: #fff;
  }

  button {
    padding: 6px 20px;
    background-color: #F70B8C;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: small;
    cursor: pointer;
  
  }

  .offer {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 12px;
    margin-bottom: 8px;
    margin-top: 5%;
  }

    tbody tr:not(:last-child) {
      border-bottom: 45px solid #444242;
    }
    tbody tr:first-child {
      border-top: 45px solid #444242;
    }
        
    @media screen and (max-width: 768px) {
      th,
      td {
        padding: 8px;
      }

      button {
        border-radius: 3px;
        padding: 4px 8px;
      }
    }

    .viewOfferButton {
      background: none;
      border: none;
      color: #F70B8C;
      cursor: pointer;
      text-decoration: underline;
    }

    
    /* Offre archivée */
  .archivedOffer {
    background-color: #6c757d;
    color: rgb(14, 14, 14); /* Couleur de texte pour les offres archivées */
  }
  /* Style du bouton "Voir l'offre" pour les offres archivées */
  .archivedOffer .viewOfferButton {
    display: none; /* Masquer le bouton pour les offres archivées */
  }
  /* Style du bouton pour afficher/cacher les offres archivées */
  .archivetitle button {
    margin-left: 5px; /* Espacement à gauche du bouton */
    cursor: pointer;
  }

}
  