@import "../../variables";

.container {
    display: flex;
    flex-direction: column;
    margin: 1.6rem 0;

    @media (min-width: $tablet) {
        margin: 3.6rem 0;
    }
}

.list_title {
    font-size: 2.1rem;
    color: $accent-color;
    margin: 0 auto 0.8rem auto;

    @media (min-width: $landscape) {
        font-size: 1.5rem;
    }

    @media (min-width: $tablet) {
        font-size: 2.8rem;
    }
}

.list_title {
    font-size: 2.1rem;
    color: $accent-color;
    margin: 0 auto 0.8rem auto;

    @media (min-width: $tablet) {
        font-size: 2.8rem;
    }
}

.list_message {
    font-size: $comment;
    text-align: center;
    margin: 0 auto;
    color: $primary-color;

    @media (min-width: $tablet) {
        font-size: unset;
    }
}