@import "../../../../variables";

.container {
    margin-bottom: 2.4rem;
    border-bottom: 3px solid $docs-color;
}

.header {
    display: flex;
    background-color: $docs-color;
    border-radius: 0.8rem 0.8rem 0 0;
    color: white;
    height: 4rem;
}

.wrapper_title {
    margin: auto 0;
    text-align: center;
    width: 65%;
}

.wrapper_eval {
    margin: auto 0;
    text-align: center;
    width: 15%;
}

.wrapper_item {
    margin: auto 0;
    text-align: center;
    width: 10%;
}

.header_item {
    text-align: center;
    margin: auto;
}

.wrapper {
    display: flex;
    margin: auto 0;
    width: 30%;
}

.bottom {
    background-color: $docs-color;
    height: 0.4rem;
}
