@import "../../../variables";

.timelineContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timelineCaptionContainer {
    display: flex;
    flex-direction: column;
    border-top: 1px solid lightgray;
    width: 80%;
    padding-top: 2rem;
}

.timelineCaption {
    font-size: 2.5rem;
    color: black;
}

.timelineTerm {
    color: black;
}

.timelineRejected {
    color: rgb(172, 5, 5);
}

.termContainer {
    display: flex;
    border-top: 1px solid lightgray;
    align-items: center;
    justify-content: space-between;
}

.termPaytweakLinkRejected {
    background-color: rgb(241, 30, 30);
    padding: 0.5rem 0.5rem 0.2rem 0.5rem;
    color: white;
    font-size: 1.2rem;
    border-radius: 3px;
    width: auto;
}

.termPaytweakLink {
    background-color: $realized;
    padding: 0.5rem 0.5rem 0.2rem 0.5rem;
    color: white;
    font-size: 1.2rem;
    border-radius: 3px;
    width: auto;
}

.invoiceBtn {
    font-size: 1.2rem;
    border: none;
    color: red;
}

.downloadLinkContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.5rem;

    @media (min-width: $desktop) {
        justify-content: center;
    }


    @media (min-width: $tablet) {
        justify-content: center;
    }
}

.downloadLogo {
    height: 25px;
    width: 25px;

    @media (min-width: $desktop) {
        margin-right: 2rem;
    }

    @media (min-width: $tablet) {
        margin-right: 2rem;
    }
}

.downloadBtn {
    background-color: rgb(212, 212, 212);
    margin: 0 auto;
    padding: 1rem;
    border: none;
    border-radius: 5px;
    margin-bottom: 2.5rem;

    &:hover {
        cursor: pointer;
    }
}

.iconColor {
    filter: invert(15%) sepia(90%) saturate(7405%) hue-rotate(1deg) brightness(99%) contrast(100%);
}