@import "../../variables";

.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.4rem;
}

.label {
    font-size: 1.4rem;
    font-weight: $regular;
    margin: 0 0 0.4rem 0;
}

.multiInput {
    width: 100%;
    padding: 10px;
    margin-top: 0.8rem;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
}

.ulDropDown {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 1px solid lightgray;
    border-radius: 8px;
    position: absolute;
    color: #000000;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    z-index: 1000;
}

.ulDropDown > li {
    padding: 0.75rem 0.5rem 0.75rem 0.5rem;
    border-bottom: 1px solid lightgray;
    transition: background-color 0.3s ease;
}

.ulDropDown > li:hover {
    background-color: $realized; /* Changer la couleur de fond au survol */
    color: white; /* Changer la couleur du texte au survol */
    cursor: pointer; /* Ajouter un pointeur pour l'effet interactif */
  }

.box {
    font-family: 'Roboto';
    font-size: 1.6rem;
    font-weight: $regular;
    color: $accent-color;
    height: 3.8rem;
    background-color: $lbg-color;
    -webkit-appearance: none;
    border: 0;
    // border-radius: 0.6rem;
    padding-left: 0.5rem;
    border-bottom: 1px solid #ced4da;
    width: 100%;
}

.box:focus {
    outline: none;
    border: 0;
    filter: none;
    box-shadow: 0 1px 0 0 #4285f4;
    border-bottom: 1px solid #4285f4;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    outline: 0;
}

.input_error {
    font-size: 1.2rem;
    font-weight: $regular;
    margin: 0;
    color: #c30000;
    margin: 1rem 0 0 0;
}

.invalid {
    border-bottom: 1px solid $error;
    // box-shadow: 0 1px 0px 0px $error;
    outline: 0;
}

.box::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #aeb4b9;
    /* padding-left: 1rem; */
    opacity: 1; /* Firefox */
    font-weight: 400;
}

.box:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #aeb4b9;
}

.box::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #aeb4b9;
}

.selectedOption {
    background-color: realized;
    color: white;
}