@import "../../../../variables";

.container {
    display: flex;
    flex-direction: column;
    background-color: $lbg-color;
    border-radius: 0.8rem;
    padding: 0 3.2rem;
    margin-bottom: 3.2rem;
}

.warning_icon_color {
    filter: $icon-warning;
}

.legend {
    gap: 40px;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px; /* Espace entre les colonnes */
    
    @media (min-width: $desktop) {
        display: grid;
    }
}

.legend_titles{
    color :red; 
    font-size: 15px; 
    text-align: center;
    font-weight: bold;

}

.legend_defenition{
    color :rgb(1, 1, 1); 
    font-size: 12px; 
    text-align: start;
    padding: 20px;
    
    display: flex;
    flex-direction: column;
    background-color: $lbg-color;
    border-radius: 0.8rem;
    padding: 0 3.2rem;
    margin-bottom: 3.2rem;
}
.legend_littletitle
{
    color :rgb(1, 1, 1); 
    font-size: 11px; 
    text-align: start;
    
}

.link
{
    color :rgb(1, 1, 1); 
    font-size: 14px; 
    text-align: start;
    padding-left: 2em;
    
}

.qrCodeContainer {
    color: black;
    text-align: center;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 1.5rem;
    padding: 0.1rem 0 0.5rem 0;
}

.important {
    color: red;
    font-weight: bold;
}

.qrCode_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.qrCode {
    width: 85px;
    height: 85px;
}