@import "../../variables";

.student_card_grid {
    display: grid;
    grid-gap: 2.4rem;
    grid-template-rows: repeat(6, 14rem);
    grid-template-columns: repeat(2, 13rem);
    margin: 3.2rem auto;

    @media (min-width: $landscape) {
        grid-gap: 3rem;
        grid-template-columns: repeat(3, 13rem);
        grid-template-rows: repeat(4, 14rem);
    }

    @media (min-width: $desktop) {
        grid-template-columns: repeat(5, 16rem);
        grid-template-rows: repeat(2, 17.8rem);
        margin: 6.4rem auto 0 auto;
    }
}

.teacher_card_grid {
    display: grid;
    grid-gap: 2.4rem;
    grid-template-rows: repeat(5, 14rem);
    grid-template-columns: repeat(2, 13rem);
    margin: 3.2rem auto;

    @media (min-width: $landscape) {
        grid-gap: 3rem;
        grid-template-columns: repeat(3, 13rem);
        grid-template-rows: repeat(4, 14rem);
    }

    @media (min-width: $desktop) {
        grid-template-columns: repeat(5, 16rem);
        grid-template-rows: repeat(2, 17.8rem);
        margin: 6.4rem auto 0 auto;
    }
}

.contractor_card_grid {
    display: grid;
    grid-gap: 2.4rem;
    grid-template-rows: repeat(4, 14rem);
    grid-template-columns: repeat(1, 13rem);
    margin: 3.2rem auto;

    @media (min-width: $landscape) {
        grid-gap: 3rem;
        grid-template-columns: repeat(3, 13rem);
        grid-template-rows: repeat(4, 14rem);
    }

    @media (min-width: $desktop) {
        grid-template-columns: repeat(4, 16rem);
        grid-template-rows: repeat(1, 17.8rem);
        margin: 6.4rem auto 0 auto;
    }
}

.schedule {
    background-color: $schedule-color;
}

.resources {
    background-color: $resources-color;
}

.docs {
    background-color: $docs-color;
}

.biblio {
    background-color: $biblio-color;
}

.email {
    background-color: $email-color;
}

.cre {
    background-color: $cre-color;
}

.slife {
    background-color: $slife-color;
}

.kcomm {
    background-color: $kcomm-color;
}

.news {
    background-color: $news-color;
}

.faq {
    background-color: $faq-color;
}

.df {
    background-color: $df-color;
}

.not_available {
    background-color: #b2b2b5;
}

.empty_space {
    background-color: $bg-color;
    box-shadow: $inside-card-shadow;
    cursor: auto;
}

.ia_logo {
    width: 10rem;
    margin: auto;
}

.icon_white {
    filter: $icon-white;
}

.cardDisabled {
    background-color: $shadow;
}

.attendanceToClose {
    
}