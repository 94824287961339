@import "../../variables";

.container {
    display: flex;
    flex-direction: column;
    background-color: $bg-footer;
    font-size: $comment;
    margin-top: auto;

    @media (min-width: $tablet) {
        height: 8rem;
    }
}

.links_wrapper {
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 7rem;

    @media (min-width: $tablet) {
        height: 8rem;
        justify-content: space-between;
    }
}

.link {
    text-decoration: none;
    margin: 1rem auto 0 auto;
    color: $primary-color;
    width: fit-content;
    width: -moz-fit-content;
    padding: 1.6rem 0;

    @media (min-width: $tablet) {
        margin: auto 0;
        padding: unset;
    }
}

.link:hover {
    color: lighten($primary-color, 15%);
}

.copyright_container {
    display: flex;
    flex-direction: column;
    background-color: $bg-footer;
    height: 4.8rem;

    @media (min-width: $tablet) {
        height: 8rem;
    }
}

.text {
    margin: auto;
}
