@import "../../variables";

.card {
    text-decoration: none;
    border-radius: 4px;
    box-shadow: $card-shadow;
    cursor: pointer;
}

.img_wrapper {
    width: 35%;
    margin: 2.4rem auto 0.8rem auto;
}

.icon {
    display: block;
    width: 100%;
}

.title {
    color: #ffffff;
    text-transform: uppercase;
    margin: 1.6rem auto auto auto;
    text-align: center;
    font-weight: $semi-bold;
    font-size: 1.5rem;

    @media (min-width: $desktop) {
        margin: 0 auto 1rem auto;
        padding: 2.4rem 1.6rem 3.2rem 1.6rem;
    }
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
    position: absolute;
    visibility: hidden;
    width: 80%;
    top: 154px;
    left: 6px;
    background-color: #000000e0;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    z-index: 1;
    font-size: 1.2rem;
    padding: 1rem;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.notificationAlert {
    @media (min-width: $desktop) {
        position: absolute;
        top: -20%;
        right: -3%;
    }
    position: absolute;
    top: -30%;
    right: -3%;
}

.cardWrapper {
    position: relative;
}