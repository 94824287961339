@import "../../../../../variables";

.input {
    width: 80%;
    margin: 0 auto;
    padding-top: 2rem;
    text-align: left;
}

.submitBtn {
    border-radius: 3px;
    width: auto;
    padding: 0.5rem 2rem 0 2rem;
    font-size: 1.3rem;
    color: white;
    background-color: $realized;
    margin: 0 auto;
    margin-top: 1.5rem;
    margin-bottom: 2rem;

    @media (min-width: $desktop) {
        padding-top: 0.7rem;
    }
}

.documentsDiv {
    margin: 0 auto;
    width: 80%;
    display: flex;
    margin-top: 2rem;
    flex-direction: column;
    border-bottom: 1px solid lightgrey;
}

.cvecP {
    font-size: 1.3rem;
}

.divCvec {
    display: flex;
    padding-bottom: 1rem;
    text-align: left;
    flex-direction: column;
    
    @media (min-width: $desktop) {
        flex-direction: row;
    }
}