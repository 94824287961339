/*          Font Types          */

$title: "Oswald", sans-serif;
$text: "Roboto", sans-serif;


/*          Font Sizes          */

$h1: 8.6rem;
$h2: 5.6rem;
$h3: 4.2rem;
$h4: 3.2rem;
$h5: 2.8rem;
$h6: 2.1rem;
$p: 1.6rem;
$comment: 1.4rem;


/*          Font Weight          */

$thin: 100;
$m-thin: 300;
$regular: 400;
$semi-bold: 500;
$bold: 700;
$bolder: 900;


/*          Colors          */

$primary-color: #6c757d;
$secondary-color: #ffffff;
$accent-color: #000000;
$disabled-color: #a2a2a2;


// Background Colors
$bg-color: #3C3C3B;
$lbg-color: #fff;
$bg-footer: rgba(0,0,0,0.1);


// School palette Colors
$esi-color: #22D597;
$ia-color: #F70B8C;
$school-texts: #0B0068;


// Calendar Leyend Colors
$cal-bg: #F3F2ED;
$call-list: #f9ac00;
$scheduled: #1a2ca9;
$realized: #1ebba5;
$invoiced: #00b61d;
$cancelled: #db3e00;
$replaced: #a9a9a9;



// MyGema palette Colors
$schedule-color: #2CB369;
$resources-color: #3EA98F;
$docs-color: #5FD9B3;
$biblio-color: #67DDE2;
$email-color: #26C2E2;
$cre-color: #34307C;
$slife-color: #5B3276;
$kcomm-color: #81316B;
$news-color: #A72E5E;
$deals-color: #c81557;
$faq-color: #D61052;
$fin-color: #2bbbad;
$df-color: #82b9e6;


// Absence state colors
$processing-color: #34307C;
$to-justify-color: #f9ac00;
$justified-color: #008900;
$justified-bg-color: #6fec6f6e;
$unjustified-color: #e400001f;
$absence-lateness-color: #f25330;


// Buttons
$btn-disabled-color: #b5b5b5;


// Anchor Colors
$a: #000000;
$a-alt: #0000ed;
$a-hover: #000000;
$on-hover: darken($a-hover, 15%);


// Icon Colors
$icon-white: invert(100%);
$icon-error: invert(9%) sepia(95%) saturate(5783%) hue-rotate(0deg) brightness(106%) contrast(115%);
$icon-warning: invert(84%) sepia(32%) saturate(7445%) hue-rotate(13deg) brightness(111%) contrast(101%);
$icon-info: invert(15%) sepia(47%) saturate(3093%) hue-rotate(233deg) brightness(84%) contrast(135%);
$icon-ok: invert(47%) sepia(45%) saturate(2344%) hue-rotate(95deg) brightness(95%) contrast(101%);
$icon-disabled: invert(76%) sepia(0%) saturate(1%) hue-rotate(168deg) brightness(98%) contrast(84%);
$icon-img: invert(11%) sepia(99%) saturate(6168%) hue-rotate(273deg) brightness(86%) contrast(121%);
$icon-pdf: invert(9%) sepia(86%) saturate(6254%) hue-rotate(3deg) brightness(116%) contrast(102%);
$icon-excel: invert(34%) sepia(93%) saturate(1109%) hue-rotate(86deg) brightness(100%) contrast(104%);
$icon-ppt: invert(65%) sepia(79%) saturate(3605%) hue-rotate(1deg) brightness(105%) contrast(104%);
$icon-word: invert(9%) sepia(93%) saturate(6706%) hue-rotate(240deg) brightness(113%) contrast(105%);


// http Colors
$ok: #00b61d;
$error: #e40000;
$warning: orange;


// Other specific Colors
$calendar-red: #e40000;
$input-underline: #4285f4;
$calendar-lines: #dddddd51;


/*         Shadows       */

$shadow: #626262;
$ok-shadow: #01a01b;
$error-shadow: #f90000;
$card-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
$inside-card-shadow: inset 0 0 10px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);


/*      Media Queries    */

$landscape: 568px;
$tablet: 768px;
$desktop: 1024px;
$intermedium: 1155px;
$flux: 1575px;