@import "../../variables";

.container {
    width: 80%;
    margin: 5.8rem auto auto auto;
}

.banner_color {
    background-color: $news-color;
}

.btn_active {
    background-color: $news-color;
    box-shadow: $card-shadow;
}

.btn_active:hover {
    background-color: $news-color;
}

.videoContainer {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    background-color: $lbg-color;
    border-radius: 0.8rem;
    padding: 3.2rem;
    min-height: 25rem;
    justify-content: space-evenly;
    margin-bottom: 3.2rem;
}

.video_title {
    color: $accent-color;
}

.video {
    display: flex;
    flex-direction: column;
}

.iframe {
    margin: 2rem 0 2rem 0;
}