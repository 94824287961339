@import "../../variables";

.container {
    width: 80%;
    margin: 5.8rem auto auto auto;
}

.banner_color {
    background-color: $slife-color;
}

.btn_active {
    background-color: $slife-color;
    box-shadow: $card-shadow;
}

.btn_active:hover {
    background-color: $slife-color;
}

.download_icon_color {
    color: $resources-color;
}

