@import "../../variables";

.container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 6.4rem auto;
    background-color: $lbg-color;
}

.wrapper {
    width: 80%;
    margin: 6.4rem auto 3.2rem auto;
}

.title {
    color: $accent-color;
    text-align: center;
    width: 65%;
    margin: 0 auto 6.4rem auto;
}

.subtitle {
    color: $accent-color;
    margin: 4rem 0 2.1rem 0;
}

.text {
    font-size: 1.5rem;
}

.btn_active_outlined {
    margin-top: 2.4rem;
    background-color: $ia-color;
    // background-color: transparent;
    // border: 3px solid $ia-color;
    box-shadow: $card-shadow;
    height: 4.2rem;
}

.btn_active_outlined:hover {
    background-color: darken($ia-color, 10%);
    // filter: drop-shadow(0 0 2px #c5366f);
}

.btn_text_outlined {
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}

.button_container {
    margin: 0 auto 8rem auto;
}