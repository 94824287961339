@import "../../variables";

.container {
    display: flex;
    background-color: $secondary-color;
    padding: 1rem 0;
    border-bottom: 1px solid #cccccc;
    width: 100%;
    margin: 0 auto;
}

.disabledContainer {
    display: flex;
    background-color: rgb(232, 232, 232);
    padding: 1rem 0;
    border-bottom: 1px solid #cccccc;
    width: 100%;
    margin: 0 auto;
    
}

.avatar {
    display: flex;
    width: 4rem;
    margin: auto 1.6rem auto 0;

    @media (min-width: $tablet) {
        width: 5rem;
    }
}

.avatar img {
    display: block;
    width: 100%;
    object-fit: cover;
}

.name {
    font-size: $comment;
    color: $bg-color;
    margin: auto 0;
    font-weight: $semi-bold;
    text-align: initial;

    @media (min-width: $tablet) {
        font-size: $p;
    }
}

.label {
    font-size: 1rem;
    display: flex;
    height: 2.4rem;
    background-color: #e4e4e4;
    margin: auto 0 auto auto;
    padding: 0 0.8rem;
    font-weight: $semi-bold;
    text-transform: uppercase;

    @media (min-width: $tablet) {
        font-size: 1.2rem;
        height: 2.8rem;
        margin: auto 1.6rem auto auto;
        padding: 0 1.6rem;    
    }
}

.lateLabel {
    font-size: 1rem;
    display: flex;
    height: 2.4rem;
    background-color: #e4e4e4;
    // margin: auto 0 auto auto;
    padding: 0 0.8rem;
    font-weight: $semi-bold;
    text-transform: uppercase;
    cursor: pointer;

    @media (min-width: $tablet) {
        font-size: 1.2rem;
        height: 2.8rem;
        margin: auto 1.6rem auto 0;
        padding: 0 1.6rem;    
    }
}

.label_text {
    margin: auto;
    width: 6.9rem;
    text-align: center;
    @media (min-width: $tablet) {
        width: unset;
    }
}

.present_label {
    background-color: #1eb5002e;
    color: $ok;
}

.absent_label {
    background-color: #e400001f;
    color: $error;
}

.warning {
    background-color: #e47a001f;
    color: $warning;
    margin: auto;
    padding: 0.7rem 1.5rem 0.7rem 1.5rem;
    
}

.warningLabel {
    font-size: 1.2rem;
    display: flex;
    margin: auto 0 auto auto;
    padding: 0 0.8rem;
    font-weight: $semi-bold;
    text-transform: uppercase;
}

.isDelay {
    background-color: #e400001f;
    color: $error;
}

.isNotDelay {
    background-color: rgb(231, 231, 231);
    color: grey;
}

.checkboxContainer {
    border-right: 2px solid lightgray;
    margin-right: 2rem;
    margin-left: 2rem;
}

.checkboxDelayContainer {
    border-left: 2px solid lightgray;
    padding-left: 2rem;
    margin-left: 0.5rem;
}