@import "../../../../../variables";

.profilContainer {
    position: relative;
    border-radius: 0.4rem;
    margin: 8rem 1.6rem 0 1.6rem;
    background-color: white;
    padding: 3.2rem 0 4.8rem 0;

    @media (min-width: $desktop) {
        width: 100%;
        margin: 8rem 0 6.4rem 0;
      }
}

.nav_container {
    display: flex;
    position: absolute;
    background-color: $realized;
    justify-content: space-evenly;
    border-radius: 0.4rem;
    box-shadow: $card-shadow;
    width: 95%;
    height: 6rem;
    left: 2.5%;
    top: -4rem;
    overflow: scroll;
    scrollbar-width: none;

    @media (min-width: $desktop) {
        width: 95%;
    }
}

.nav {
    width: 20%;
}

.nav_btns {
    display: flex;
    margin-bottom: 3.2rem;
    justify-content: flex-start;
    gap: 1.6rem;
    flex-wrap: wrap;
}

.activeBtn {
    margin: auto;
    display: flex;
    height: 2.8rem;
    width: 100%;
    border: 0;
    border-bottom: 1px solid white;
    cursor: pointer;

    @media (min-width: $tablet) {
        width: unset;
    }
}

.btn {
    margin: auto;
    display: flex;
    height: 2.8rem;
    width: 100%;
    border: 0;
    border-bottom: 1px solid transparent;
    cursor: pointer;

    @media (min-width: $tablet) {
        width: unset;
    }
}

.btn:hover {
    border-bottom: 1px solid white;
}

.btn_text {
    text-transform: uppercase;
    color: $secondary-color;
    font-size: $p;
    font-weight: $regular;
    padding: 0;
    margin: auto;
}