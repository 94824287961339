@import "./variables";
@import "./placeholders";

.central {
    display: inherit;
    flex-direction: inherit;
    height: 100%;
}

.container {
    margin: 1.6rem;

    @media (min-width: $desktop) {
        width: 80%;
        margin: 3.2rem auto 6.4rem auto;
    }
}

.tab_container {
    height: auto;
    margin: 1.6rem;

    @media (min-width: $tablet) {
        margin: 3.2rem 3.2rem 0 3.2rem;
    }

    @media (min-width: $desktop) {
        width: 85%;
        margin: 3.2rem auto;
    }
}

.error_wrapper {
    display: flex;
    width: 60%;
    background-color: $lbg-color;
    border-radius: 0.8rem;
    margin: auto;
}

.error_message {
    font-size: 1.5rem;
    font-weight: 500;
    color: $error;
    z-index: 10;
    text-align: center;
    padding-bottom: 1rem;
}
