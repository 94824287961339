@import "../../variables";

.container {
    display: flex;
    background-color: $secondary-color;
    margin-bottom: 6.4rem;
    border-radius: 0.4rem;
    box-shadow: 0px 35px 35px rgba(0, 0, 0, 0.16);
    padding-bottom: 1rem;

    @media (min-width: $desktop) {
        display: flex;
        background-color: $secondary-color;
        margin-bottom: 6.4rem;
        border-radius: 0.4rem;
        box-shadow: 0px 35px 35px rgba(0, 0, 0, 0.16);
        padding-bottom: 1rem;
    }
}

.img_wrapper {
    display: inherit;
    width: 40%;
}

.img {
    display: block;
    width: 100%;
}

.text_container {
    display: inherit;
    width: auto;
    @media (min-width: $desktop) {
        width: 60%;
    }
}

.text_wrapper {
    padding: 1.3rem;
    @media (min-width: $desktop) {
        padding: 0 4.8rem;
    }
}

.title {
    font-size: 2.2rem;
    font-weight: 900;
    color: $accent-color;
    text-align: left;

    @media (min-width: $desktop) {
        font-size: 3.2rem;
    }
}

.date {
    color: $accent-color;
}

.content {
    line-height: 1.5;
}