@import "../../../../../variables";

.header {
    display: flex;
    flex-direction: column;
    padding: 1.6rem 1.6rem 0.4rem 1.6rem;
    box-shadow: $card-shadow;
    color: $accent-color;

    @media (min-width: $tablet) {
        flex-direction: row;
        padding: 2.1rem 6rem;
    }
}

.mobile_title_block {
    display: flex;
    // margin-bottom: 1.2rem;

    @media (min-width: $landscape) {
        margin-bottom: unset;
    }
}

.mobile_info_block {
    display: flex;
    justify-content: space-between;
    // flex-direction: column;

    @media (min-width: $landscape) {
        flex-direction: row;
        justify-content: space-evenly;
    }
    
}

.date {
    display: inherit;
    flex-direction: column;
    padding: 0 1.6rem 0 0.8rem;
    border-right: 1px solid $accent-color;
    width: 3.2rem;

    @media (min-width: $tablet) {
        padding: 1rem 3.2rem 0.6rem 1.6rem;
        line-height: 3.2rem;
        width: unset;
    }
}

.date_day {
    font-size: 3rem;
    font-weight: $m-thin;
    text-align: center;
    margin-top: auto;

    @media (min-width: $tablet) {
        font-size: 3.6rem;
    }
}

.date_month {
    font-size: 1.8rem;
    font-weight: $regular;
    text-align: center;
    color: $calendar-red;
    letter-spacing: 0.1rem;
    margin-bottom: auto;

    @media (min-width: $tablet) {
        font-size: 2rem;
    }
}

.head {
    flex-direction: column;
    margin: auto auto auto 0;

    @media (min-width: $tablet) {
        line-height: 3.2rem;
        padding: 0 1.6rem 0 3.2rem;
    }
}

.head_title {
    font-size: 1.8rem;
    font-weight: $semi-bold;
    margin: auto 0 auto 1.6rem;
    text-align: initial;
    line-height: 1.3;

    @media (min-width: $tablet) {
        line-height: unset;
        font-size: 2.1rem;
        margin: 0;
    }
}

.head_slot {
    display: flex;
    margin: auto 0;
    // margin: 0 auto;
    color: $primary-color;
    text-align: initial;
    font-size: 1.4rem;
    text-align: center;

    @media (min-width: $landscape) {
        margin: auto 0;
    }

    @media (min-width: $tablet) {
        margin: 0;
        font-size: unset;
        text-align: initial;
    }
}

.classroom {
    text-align: center;
    text-transform: uppercase;
    color: $a-alt;
    font-weight: $bold;
    margin: 1.6rem 0;

    @media (min-width: $landscape) {
        margin: 1.6rem 0 1.6rem 1.6rem;
    }

    @media (min-width: $landscape) {
        text-align: left;
        margin: 0;
    }
}

.link {
    display: flex;
    // margin: 0 auto;
    padding: 1.6rem 0;
    text-decoration: none;
    font-size: 1.4rem;

    @media (min-width: $tablet) {
        margin: 0.4rem 0 0 0;
        padding: unset;
    }
}

.link:visited {
    color: $a-alt;
}

.link_container {
    filter: invert(10%) sepia(97%) saturate(7039%) hue-rotate(246deg) brightness(80%) contrast(145%);
    display: inherit;
}

// .link_container {
//     background-color: #3d1319;
// }

// .link_container::before {
//     content: "";
//     position: absolute;
//     width: 100%;
//     height: 3px;
//     bottom: 0;
//     left: 0;
//     visibility: hidden;
//     -webkit-transform-origin: 0% 50%;
//     transform-origin: 0% 50%;
//     -webkit-transform: scaleX(0);
//     transform: scaleX(0);
//     -webkit-transition: 0.2s cubic-bezier(0.32, 0, 0.07, 1);
//     transition: 0.2s cubic-bezier(0.32, 0, 0.07, 1);
// }

.link_text {
    margin: 0;
    line-height: 1.7rem;
}

.link_icon_container {
    margin: auto 0 auto 0.8rem;
    height: 1.6rem;
}

.link_icon {
    height: 100%;
    display: block;
}

.groups {
    display: inherit;
    flex-direction: column;
    max-width: 20%;
    margin: 0rem auto auto auto;
}

.group_label {
    margin: 0;
    margin-bottom: 0.8rem;
    font-weight: $bold;
}

.group_names {
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: $primary-color;
    text-align: left;
    margin: 0;
}
