@import "../../variables";

.banner_color {
    background-color: $docs-color;
}

.btn_active {
    background-color: $docs-color;
    box-shadow: $card-shadow;
}

.btn_active:hover {
    background-color: $docs-color;
}