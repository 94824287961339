@import "../../variables";

.img {
    max-width:100%;
    max-height:100%;
}

.pCard {
    text-align: center;
    color: rgb(177, 177, 177);
    font-weight: bold;
    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }
}