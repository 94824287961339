@import "../../../variables";


/* Link Button styles */
.link_btn {
    display: flex;
    height: 4.8rem;
    background-color: transparent;
    border: 0;
    padding: 0 1.6rem;
    cursor: pointer;
}

.link_btn:hover .text {
    color: darken($a-alt, 10%);
    border-bottom: 1px solid $a-alt;
}

.link_btn_icon {
    height: 2.4rem;
    margin: auto 1.6rem auto 0;
}

.link_btn_text {
    font-size: $comment;
    font-weight: $regular;
    color: $a-alt;
    margin: auto 0;
}

.link_btn_wrapper {
    display: flex;
    width: 100%;
}

/* Banner Button styles */
.banner_btn {
    display: flex;
    padding: 1.3rem;
    font-size: $p;
    background-color: $secondary-color;
    box-shadow: $card-shadow;
    margin-bottom: 1.6rem;
    width: 100%;
    border: 0;
    cursor: pointer;

    @media (min-width: $desktop) {
        height: 4.8rem;
        padding: 0 9rem;
        align-items: center;
    }
}

.banner_btn_icon {
    height: 2rem;
    margin: auto 2.4rem auto 0;
    filter: invert(53%) sepia(93%) saturate(273%) hue-rotate(115deg) brightness(91%) contrast(85%);
}

.banner_btn_text {
    text-transform: uppercase;
    color: $bg-color;
    margin: auto 0;
    font-weight: $bolder;
}

.banner_btn_wrapper {
    display: flex;
    width: 100%;
}
.btn_deverouiller
{
    background-color: #3EA98F !important;
    color: #fff!important;
    margin-left:5%;
    border-radius: 10em;
    padding: 0.84rem 2.14rem;
    font-size: 1.2rem;
}