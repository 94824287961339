@import "../../../variables";

.newbanner {
    flex-direction: column;
    padding-left: 1rem;
    background-color: white;
    margin-bottom: 1.6rem;
   // cursor: pointer;
    
}


.container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 0.8rem;
    padding: 0 3.2rem;
    margin-bottom: 3.2rem;
}

.archivetitle{
    color:white;
    font-family: "Roboto", sans-serif;

}
.archivetitle button {
    background-color: transparent;
    border: none;
    color: #faf8f8;
    font-size: 16px;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .archivetitle button:hover {
    color: #999;
  }
  .archivedOffer {
    background-color: #b13434; /* Couleur de fond pour les offres archivées */
    color: #999; /* Couleur de texte pour les offres archivées */
    text-decoration: line-through; /* Texte barré pour les offres archivées */
  }

  .offerFilterContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .inputFilter {
    width: 45%;
  }

  .selectInput {
    border-radius: 3px;
  }
  
  .datePicker {
    display: flex;
    flex-direction: column;
    // align-items: center;
    width: 45%;
    margin-bottom: 5px;
    font-size: 1.4rem;
  }

  .datePickerInput {
    margin-top: 4px;
    border-radius: 3px;
    padding: 1.1rem;
    width: 96%;
    border: none;
  }

  .filterButtons {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-bottom: 2.5rem;
  }

  .refreshButton {
    background-color: $cre-color;
    width: 30%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 3px;
    color: white;
    box-shadow: $card-shadow;
  }

  .refreshIcon {
    margin: 0 auto; 
  }

  .deleteFilterButton {
    background-color: $faq-color;
    width: 30%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 3px;
    color: white;
    box-shadow: $card-shadow;
  }

  .deleteFilterIcon {
    margin: 0 auto;
  }