@import "../../../../../variables";

.modal_container {
    position: fixed;
    z-index: 1000;
    height: auto;
    // width: 60%;
    margin-left: 8%;
    right: 10%;
    top: 5%;
    background-color: white;
    border-radius: 5px;

    @media (min-width: $tablet) {
        height: 95%;
        width: 60%;
        margin: 0 auto;
        right: 20%;
        top: 5%;
    }
}

.banner_color {
    background-color: $schedule-color;
}

.list_header {
    display: flex;
    flex-direction: column;
    height: calc(100% - 11.4em);
    overflow:scroll;
    margin-top: 0.67rem;

    @media (min-width: $landscape) {
        height: calc(100% - 9.4em);
    }

    @media (min-width: $tablet) {
        height: calc(100% - 14.8em);
    }

    @media (min-width: $desktop) {
        height: calc(100% - 12.8em);
    }
}

.spinner_text_color {
    color: $accent-color;
    font-size: 1.2rem;
}

.form {
    width: 90%;
    margin: 0 auto;

    @media (min-width: $tablet) {
        width: 75%;
    }

    @media (min-width: $desktop) {
        width: 70%;
    }
}

.footer {
    display: flex;
    margin: 4.8rem auto;
}

.btn_active {
    background-color: $schedule-color;
    box-shadow: $card-shadow;
    border-radius: 0.2rem;
}

.btn_active:hover {
    background-color: darken($schedule-color, 5%);
}

.btn_active_alt {
    background-color: $scheduled;
    box-shadow: $card-shadow;
    border-radius: 0.2rem;
}

.btn_active_alt:hover {
    background-color: darken($scheduled, 5%);
}

.btn_text {
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}

.btn_icon {
    filter: $icon-white;
    margin-right: 0.8rem;
    height: 2rem;
}


.attedanceDelayP {
    color: black;
    display: flex;
    position: relative;
    margin-bottom: 5rem;
}

.attendanceColumn {
    position: absolute;
    left: -0.5%;
}

.delayColumn {
    position: absolute;
    right: 1.5%;
}