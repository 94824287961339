@import "../../../../../variables";

.container {
    display: flex;
    background-color: $secondary-color;
    padding: 1rem 0;
    border-bottom: 1px solid #cccccc;
}

.name {
    font-size: $comment;
    font-weight: $semi-bold;
    color: $bg-color;
    text-align: initial;
    width: 50%;

    @media (min-width: $tablet) {
        width: 35%;
        margin: auto 0;
    }
}

.label {
    display: flex;
    height: 2.8rem;
    background-color: #e4e4e4;
    margin: auto 1.6rem auto auto;
    padding: 0 1.6rem;
    font-size: 1.2rem;
    font-weight: $semi-bold;
    text-transform: uppercase;
}

.labels_container_alt {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 10%;
    width: 67%;
    align-content: center;

    @media (min-width: $tablet) {
        flex-direction: row;
        width: 75%;
        //margin-bottom: 1rem;
    }
}

.text {
    display: flex;
    font-size: $comment;
    font-weight: $regular;
    color: $accent-color;
    margin: 0 0 1rem 1.5rem;
    // margin: auto 1.6rem auto auto;
    // padding: 0 0.8rem 0 0;
    @media (min-width: $tablet) {
        margin: auto;
    }
}
