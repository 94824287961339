@import "../../variables";


.poleContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-bottom: 1rem;

}

.itemContainer {
    display: flex;
    flex-wrap: wrap;
    @media (min-width: $tablet) {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
      }
}


.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 2rem;

    @media (min-width: $tablet) {
        width: 30%;
      }
}

.title {
    margin: 0 auto;
    margin-bottom: 3rem;
    margin-top: 2rem;
    color: $docs-color;
    font-weight: bold;
}

.avatar {
    margin: 0 auto;
    height: 80px;
    width: 80px;
}

.name {
    text-align: center;
    color: black
}

.mail {
    font-size: 1rem;
}