@import "../../../../variables";

.container {
    display: flex;
    flex-direction: column;
    border-bottom: 3px solid $docs-color;
}

.header {
    display: flex;
    background-color: $docs-color;
    border-radius: 0.8rem 0.8rem 0 0;
    color: white;
    height: 4rem;
}

.wrapper_subject {
    margin: auto 0;
    text-align: center;
    width: 60%;

    @media (min-width: $tablet) {
        margin: auto;
        text-align: left;
        width: auto;
    }
}

.wrapper_subject_alt {
    margin: auto;
    text-align: center;
}

.wrapper_item {
    margin: auto 0;
    text-align: center;
    width: 11rem;
}

.wrapper_motif {
    margin: auto 0;
    text-align: center;
    width: 20rem;
}

.header_item {
    text-align: center;
    margin: auto;
}

.wrapper {
    display: flex;
    margin: auto 0;
    width: 30%;
}

.bottom {
    background-color: $docs-color;
    height: 0.4rem;
}

.containerMotiv {
    display: flex;
    margin-top: 1rem;
    background-color: $lbg-color;
    // border-radius: 0.8rem;
    justify-content: space-between;
    padding: 0 3.2rem;
    margin-bottom: 3.2rem;
    
    
    @media (min-width: $desktop) {
        flex-direction: column;
    }
}

.warning_icon_color {
    filter: $icon-warning;
}

.legend {
    gap: 40px;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px; /* Espace entre les colonnes */
    
    @media (min-width: $desktop) {
        display: grid;
    }
}

.legend_titles{
    color :red; 
    font-size: 15px; 
    text-align: center;
    font-weight: bold;
}

.legend_defenition {
    color :rgb(1, 1, 1); 
    font-size: 12px; 
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: $lbg-color;
    border-radius: 0.8rem;
    padding: 0 3.2rem;
    margin-bottom: 3.2rem;
}

.legend_littletitle
{
    color :rgb(1, 1, 1); 
    font-size: 13px; 
    text-align: center;
}

.link
{
    color :rgb(1, 1, 1); 
    font-size: 14px; 
    text-align: start;
    padding-left: 2em;
}
