@import "../../variables";

.spinner_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spinner_text {
    text-align: center;
    font-size: 1.2rem;
    color: $secondary-color;
}

.lds_dual_ring {
    display: inline-block;
    width: 64px;
    height: 64px;
}

.lds_dual_ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid $news-color;
    border-color: $news-color transparent $news-color transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.loading_spinner__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.in_line_lds_dual_ring {
    display: inline-block;
}

.in_line_lds_dual_ring:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 1px;
    border-radius: 50%;
    border: 3px solid $news-color;
    border-color: $news-color transparent $news-color transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.in_line_loading_spinner__overlay {
    height: 100%;
    width: 100%;
    display: flex;
    background: transparent;
    margin: auto auto auto 0.8rem;
    justify-content: center;
    align-items: center;
}


@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}