@import "../../variables";

.wrapper {
    display: flex;
    padding: 3.2rem;
    margin: 3.2rem;
    // border: 1px solid $secondary-color;

    @media (min-width: $tablet) {
        width: 50%;
        height: 50%;
        margin: auto;
    }

    @media (min-width: $desktop) {
        width: 29rem;
        height: 55rem;
    }
}

.header {
    display: flex;
    flex-direction: column;
    margin: 2.6rem auto 4.8rem;
}

.logo {
    width: 100%;
    margin: auto;
}

.logo_img {
    display: block;
    width: 100%;
    height: auto;
}

.title {
    font-size: $h5;
    font-weight: bolder;
    display: flex;
    margin: 6rem auto 3.2rem auto;
    color: $secondary-color;
}

.text {
    color: $secondary-color;
    text-align: center;
    font-weight: $semi-bold;
    margin: 0 auto 3.2rem auto;
}

.input {
    margin-bottom: 3.2rem;
}

.btn_active {
    background-color: $esi-color;
    box-shadow: $card-shadow;
    height: 4.2rem;
}

.btn_active:hover {
    background-color: darken($esi-color, 10%);
}

.btn_text {
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}

.btn_active_outlined {
    margin-top: 2.4rem;
    background-color: $ia-color;
    // background-color: transparent;
    // border: 3px solid $ia-color;
    box-shadow: $card-shadow;
    height: 4.2rem;
}

.btn_active_outlined:hover {
    background-color: darken($ia-color, 10%);
    // filter: drop-shadow(0 0 2px #c5366f);
}

.btn_text_outlined {
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}

