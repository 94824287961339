@import "../../../../variables";

.container {
    display: flex;
    flex-direction: column;
    
    @media (min-width: $tablet) {
        margin: 3.2rem;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.4rem;
}

.btn_container {
    margin: auto;
}

.subtitle {
    font-size: $h5;
    margin: auto;
    color: $accent-color;
    text-align: center;
}

.iconColor {
    filter: invert(15%) sepia(90%) saturate(7405%) hue-rotate(1deg) brightness(99%) contrast(100%);
}

