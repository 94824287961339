@import "../../../../variables";

.container {
    display: flex;
    border-bottom: 1px solid $calendar-lines;
}

.wrapper_title {
    display: flex;
    text-align: left;
    width: 65%;
}

.title {
    color: $bg-color;
    margin: auto 1.6rem;
}

.wrapper_eval {
    margin: auto 0;
    text-align: center;
    width: 15%;
}

.wrapper_item {
    margin: auto 0;
    text-align: center;
    width: 10%;
}

.text {
    font-size: $comment;
    font-weight: $regular;
    color: $accent-color;
    text-align: center;
}

.gap {
    margin: auto 1.6rem;
}

.tx_unjustified {
    color: $error;
}

.bg_unjustified {
    background-color: lighten(#e400001f, 15%);
}


.tx_enterprise {
    color: $cre-color;
}

.bg_enterprise {
    background-color: lighten($cre-color, 55%);
}


.tx_medical {
    color: $realized;
}

.bg_medical {
    background-color: lighten($realized, 40%);
}


.tx_other {
    color: blue;
}

.bg_other {
    background-color: lighten($realized, 15%);
}
