@import "../../variables";

.logoutWrapper {
    display: flex;
    margin: 1.6rem 0;
    justify-content: flex-end;

    @media (min-width: $tablet) {
        margin: 1.6rem 0 0 auto;
    }

    @media (min-width: $desktop) {
        padding-right: 0;
        width: 90%;
        margin: 1.6rem auto 0 auto;
    }
}

.container {
    background-color: $lbg-color;
    border-radius: 0.8rem;
    margin: 0 auto 6.4rem auto;
    padding: 3.2rem 6.4rem;
}

.form {
    margin: 4.8rem 0;
    display: grid;
    grid-gap: 4.8rem;
    grid-template-columns: repeat(1, 1fr);

    @media (min-width: $tablet) {
        grid-template-columns: repeat(2, 2fr);
    }
}

.btn_active {
    background-color: $ia-color;
    box-shadow: $card-shadow;
    margin: 0 auto 3.2rem auto;
}

.btn_active:hover {
    background-color: darken($ia-color, 5%);
}

.btn_text {
    margin: auto;
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}

.logout_btn {
    width: 22rem;
    height: 4.8rem;
    border-radius: 0;
    background-color: transparent;
    margin: auto 0;
    padding: 0;
}

.whiteIcon {
    filter: $icon-white;
}