@import "../../variables";

.details .summary::-webkit-details-marker {
    display:none;
  }

.summary {
    outline: none;
    padding: 1rem;
    display: flex;
    background: $secondary-color;
    background-color: $secondary-color;
    color: $accent-color;
    padding-left: 3.2rem;
    position: relative;
    cursor: pointer;

        &:hover, &:focus {
            color: $secondary-color;
            background-color: $faq-color;
        }
  }

  .details {
    box-sizing: border-box;
    margin-bottom: 0.8rem;
    background-color: $secondary-color;
  }

  .details[open] > .summary:before {
    transform: rotate(90deg);
  }

  .summary:before {
    content: '';
    border-width: .4rem;
    border-style: solid;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    top: 1.3rem;
    left: 1rem;
    transform: rotate(0);
    transform-origin: .2rem 50%;
    transition: .25s transform ease;
  }
  
  .content {
    border-top: none;
    padding: 10px;
    border: 2px solid #888;
    border-top: none;
  }
  
  .text {
    margin: 1rem 1rem 1rem 1rem;
    padding-bottom: 10px;
    &:last-child {
      padding: 0 1rem 1rem 1rem;
    }
  }
  
  
  