@import "../../variables";

.container {
    width: 80%;
    margin: 5.8rem auto auto auto;
}

.banner_color {
    background-color: $biblio-color;
}

.download_icon_color {
    color: $resources-color;
}

.btn_active {
    background-color: $biblio-color;
    box-shadow: $card-shadow;
}

.btn_active:hover {
    background-color: $biblio-color;
}

.cardContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.biblioCard {
    background-color: white;
    color: black;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    
    @media (min-width: $desktop) {
        width: 30%;
        height: 60%;
    }

    @media (min-width: $landscape) {
        width: 30%;
        height: 60%;
    }
}

.backButton {
    background-color: lightgray;
    color: white;
    width: auto;
    font-weight: 400;
    text-align: center;
    padding: 1rem 1rem 1rem 1rem;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
        
    @media (min-width: $desktop) {
        width: 30%;
        margin-left: 35%;

    }

    @media (min-width: $landscape) {
        width: 30%;
        margin-left: 35%;
    }
}

.itemContainer {
    color: $biblio-color;
}

.moocsSection {
    @media (min-width: $desktop) {
        display: grid;
        grid-gap: 3.2rem;
        grid-template-columns: repeat(3, 3fr);
    }

    @media (min-width: $landscape) {
        display: grid;
        grid-gap: 3.2rem;
        grid-template-columns: repeat(3, 3fr);
    }
}

.itemVideo {
    background-color: white;
    border-radius: 4px;
}

.videoTitle {
    width: 80%;
    margin: 2.4rem auto;
    text-align: center;
    font-size: $comment;
    font-weight: $semi-bold;
}

.itemLink {
    background-color: white;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    border-radius: 4px;
    margin-bottom: 2rem;
}

.anchor {
    font-size: 1.2rem;
}