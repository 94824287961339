@import "../../../../../variables";

.container {
    height: 57vh;
    min-width: 65vw;
    max-width: 70vw;
}

.wrapper {
    width: 90%;
    margin: 0 auto;
}

.banner_color {
    background-color: $biblio-color;
}

.btn_active {
    background-color: $biblio-color;
    box-shadow: $card-shadow;
    margin: 3.2rem auto;
    border-radius: 0.2rem;
}

.btn_active:hover {
    background-color: darken($biblio-color, 15%);
}

.btn_text {
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}

.spinner_text_color {
    color: $accent-color;
    font-size: 1.2rem;
}
