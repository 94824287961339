@import "../../../../../variables";


.profilDetailContainer {
    background-color: white;
    margin: 4rem 1.6rem 3.2rem 1.6rem;
    border-radius: 0.4rem;
    padding-top: 3rem;
    padding-bottom: 1rem;
    text-align: center;

    @media (min-width: $desktop) {
        margin: 4rem 0 0 1.6rem;
        margin-left: 3rem;
        height: 38rem;
    }
}

.input {
    width: 80%;
    margin: 0 auto;
    padding-top: 2rem;
}

.avatar {
    margin: 0 auto;
}

.styleAvatar {
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    object-fit: cover;
}
