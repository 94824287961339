@import "./variables";
@import "./placeholders";

html {
    /* set base font-size to equiv "10px", which is adapted to rem unit */
    font-size: 62.5%;
    /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
    font-size: calc(1em * 0.625);
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    height: inherit;
    width: 100%;
    font-size: 1.6em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    background-color: $bg-color;
    @extend %typography-text;
}

button {
    font-family: $text;
    font-size: 9px;
}