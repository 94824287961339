@import "../../../variables";

.contained {
    display: flex;
    height: 3.8rem;
    width: 100%;
    border: 0;
    border-radius: 0.6rem;
    cursor: pointer;

    @media (min-width: $tablet) {
        width: unset;
        padding: 0 3.2rem;
    }
}

.outlined {
    display: flex;
    height: 3.8rem;
    width: 100%;
    background-color: transparent;
    border-radius: 0.6rem;
    cursor: pointer;

    @media (min-width: $tablet) {
        width: unset;
        padding: 0 3.2rem;
    }
}

.text {
    display: flex;
    border: 0;
    cursor: pointer;
    height: 4.8rem;
    width: 4.8rem;
    background-color: transparent;

    @media (min-width: $tablet) {
        width: unset;
    }
}

.block {
    margin: auto;
    display: flex;
    height: 2rem;
    width: max-content;

    @media (min-width: $tablet) {
        height: 2.4rem;
    }
}

.icon {
    display: block;
    height: 100%;
    @media (min-width: $tablet) {
        margin-right: 0.8rem;
    }
}

.notificationAlert {
    @media (min-width: $desktop) {
        position: absolute;
    }
    position: absolute;
}

.relativeParent {
    position: relative;
}