@import "../../variables";

.avatar {
    display: flex;
    width: 4.8rem;
    height: 4.8rem;
    margin: auto 1rem auto 0;
}

.avatar_img {
    display: block;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}