@import "../../variables";

.container {
    width: 80%;
    margin: 5.8rem auto auto auto;
}

.banner_color {
    background-color: $biblio-color;
}

.btn_active {
    border-radius: 0.2rem;
    background-color: $biblio-color;
    box-shadow: $card-shadow;
}

.btn_active:hover {
    background-color: darken($biblio-color, 5%);
}

.btn_text {
    // text-transform: uppercase;
    
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
}

.subheader_wrapper {
    display: flex;
    flex-direction: column;
    background-color: $lbg-color;
    border-radius: 0.8rem;
    padding: 0 3.2rem;
}